import {
  Component,
  Input,
  Output,
  EventEmitter,
  Injector,
  HostListener,
  ViewChild,
  ElementRef
} from '@angular/core';
import {NG_VALUE_ACCESSOR, Validators} from '@angular/forms';
import {TranslateService} from '@ngx-translate/core';
import {ControlValueAccessorConnectorComponent} from '../control-value-accessor-connector.component';
import { HttpEventType, HttpResponse } from '@angular/common/http';
import {AlertService, LoaderService, MakeCallService, TokenStorage, UploadService} from 'foo-framework';
import {get, set, remove, isString} from 'lodash';
import {Observable} from 'rxjs';
import * as FileSaver from 'file-saver';
import {DomSanitizer} from '@angular/platform-browser';

@Component({
  selector: 'framework-uploader',
  templateUrl: './uploader.component.html',
  styleUrls: ['./uploader.component.scss'],
  providers: [{
    provide: NG_VALUE_ACCESSOR, useExisting: UploaderComponent, multi: true
  }]
})
export class UploaderComponent extends ControlValueAccessorConnectorComponent {

  @Input() showProgress = true;
  @Input() callbackPath: string | { [key: string]: string };
  @Input() isBase64 = false;
  @Input() multiple = false;
  @Input() fileType = '';
  @Input() fileSize = 5;
  @Input() fileSizeErrorMessage = this.translate.instant('uploader.fileSizeErrorMessage', {fileSize: this.fileSize});
  @Input() path: string;
  @Input() labelInput;
  @Input() uploadNote = '';
  @Input() extensions: any;
  @Input() params: any;
  // We can set requiredStar to false in a project where we do not need a star for required
  @Input() requiredStar = true;

  //To return the file to front without calling upload API
  @Input() returnAsFile = false;

  @Input() returnFileWithInfo = false;

  @Input() hasEditPerminssion = false;
  @Input() hasDeletePerminssion = true;
  @Input() hasDownloadPerminssion = true;
  @Input() downloadMultiple = false;

  // @Input() formGroupToAccess;
  @Output() onUpload = new EventEmitter<any>();
  @Output() onDelete = new EventEmitter<any>();
  curLang: string;
  blobUrl: string;

  selectedFiles?: FileList;
  currentFile?: any;
  progress = 0;

  fileMimeTypes = {
    image: 'image/*',
    video: 'video/*',
    text: 'text/plain',
    csv: 'text/csv',
    excel: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
    excelxls: 'application/vnd.ms-excel',
    word: 'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
    aac	:	'audio/aac',
    abw	:	'application/x-abiword',
    arc	:	'application/x-freearc',
    avif	:	'image/avif',
    avi	:	'video/x-msvideo',
    azw	:	'application/vnd.amazon.ebook',
    bin	:	'application/octet-stream',
    bmp	:	'image/bmp',
    bz	:	'application/x-bzip',
    bz2	:	'application/x-bzip2',
    cda	:	'application/x-cdf',
    csh	:	'application/x-csh',
    css	:	'text/css',
    doc	:	'application/msword',
    docx	:	'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
    eot	:	'application/vnd.ms-fontobject',
    epub	:	'application/epub+zip',
    gz	:	'application/gzip',
    gif	:	'image/gif',
    html	:	'text/html',
    ico	:	'image/vnd.microsoft.icon',
    ics	:	'text/calendar',
    jar	:	'application/java-archive',
    jpeg	:	'image/jpeg',
    jpg	:	'image/jpeg',
    js	:	'text/javascript',
    json	:	'application/json',
    jsonld	:	'application/ld+json',
    mid:	'audio/midi', //audio/x-midi
    midi	:	'audio/midi', //audio/x-midi
    mjs	:	'text/javascript',
    mp3	:	'audio/mpeg',
    mp4	:	'video/mp4',
    mpeg	:	'video/mpeg',
    mpkg	:	'application/vnd.apple.installer+xml',
    odp	:	'application/vnd.oasis.opendocument.presentation',
    ods	:	'application/vnd.oasis.opendocument.spreadsheet',
    odt	:	'application/vnd.oasis.opendocument.text',
    oga	:	'audio/ogg',
    ogv	:	'video/ogg',
    ogx	:	'application/ogg',
    opus	:	'audio/opus',
    otf	:	'font/otf',
    png	:	'image/png',
    pdf	:	'application/pdf',
    php	:	'application/x-httpd-php',
    ppt	:	'application/vnd.ms-powerpoint',
    pptx	:	'application/vnd.openxmlformats-officedocument.presentationml.presentation',
    rar	:	'application/vnd.rar',
    rtf	:	'application/rtf',
    sh	:	'application/x-sh',
    svg	:	'image/svg+xml',
    tar	:	'application/x-tar',
    tif	:	'image/tiff',
    tiff	:	'image/tiff',
    ts	:	'video/mp2t',
    ttf	:	'font/ttf',
    txt	:	'text/plain',
    vsd	:	'application/vnd.visio',
    wav	:	'audio/wav',
    weba	:	'audio/webm',
    webm	:	'video/webm',
    webp	:	'image/webp',
    woff	:	'font/woff',
    woff2	:	'font/woff2',
    xhtml	:	'application/xhtml+xml',
    xls	:	'application/vnd.ms-excel',
    xlsx	:	'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
    xml	:	'application/xml', // application/xml is recommended as of RFC 7303 (section 4.1), but text/xml is still used sometimes.
    xul	:	'application/vnd.mozilla.xul+xml',
    zip	:	['application/zip', 'application/zip-compressed', 'application/x-zip-compressed'],
  }

  hasError = false;
  isRequired = false;
  acceptString = '';
  displayedExtensions = '';


extensionsByFileType = {
    image: ['png','jpg','jpeg','svg','gif','bmp'],
    video: ['avi','mp4','mpeg','ogv','webm'],
    txt: ['txt'],
    audio: ['aac','mid','midi','mp3','oga','opus','wav','weba'],
    document: ['pdf'],
    zip: ['zip','rar'],
    font: ['otf','ttf','woff','woff2'],
    csv: ['xls','xlsx','csv'],
    presentation: ['odp','ppt','pptx'],

}
  filesCount = 0;
  @ViewChild('fileInput', {static: false}) fileInput: ElementRef;


  constructor(protected uploadService: UploadService,
              protected translate: TranslateService,
              protected alert: AlertService, injector: Injector,
              protected loaderService: LoaderService,
              protected makeCall: MakeCallService,
              protected sanitizer: DomSanitizer,
              protected tokenStorage: TokenStorage
  ) {
    super(injector);
  }

  fileInfoDetails: any;
  sanitize(url:string){
    return this.sanitizer.bypassSecurityTrustUrl(url);
  }


  ngOnInit(): void {
    if(this.fileType && !this.extensions){
       this.extensions = this.extensionsByFileType[this.fileType];
    }

    if(this.extensions){
      if(!Array.isArray(this.extensions)){
        this.extensions = this.extensions.split(',');
      }
      this.displayedExtensions = this.extensions.join(', ');

      for(var i=0; i < this.extensions.length; i++){
        this.extensions[i] = this.extensions[i].toLowerCase().replace(/\s/g,'');
        if(this.extensions[i][0] == '.'){
          this.extensions[i] = this.extensions[i].substring(1);
        }
      }

      if(!this.extensions.includes('jpeg') && this.extensions.includes('jpg')){
        this.extensions.push('jpeg');
      }else if (this.extensions.includes('jpeg') && !this.extensions.includes('jpg')){
        this.extensions.push('jpg');
      }
    }
    this.isRequired = this.requiredStar && this.control.hasValidator(Validators.required);
    this.curLang = this.tokenStorage.getCurrentLanguage();
    this.fileSizeErrorMessage = this.translate.instant('uploader.fileSizeErrorMessage', {fileSize: this.fileSize});

    if (this.control.value != null && this.control.value !== '') {
      this.currentFile = this.control.value;
      if(this.returnAsFile){
        this.blobUrl = this.control.value;
      }
    }
    this.control.valueChanges.subscribe(current => {
      this.currentFile = current;
      if (!this.returnAsFile) {
        this.fileInfo();
      }
    });
    if (this.currentFile && !this.fileInfoDetails) {
      this.fileInfo();
    }

    if(this.extensions && this.extensions.length>0){
      let acceptStringArray = [];
      for (const item of this.extensions) {
        if(this.fileMimeTypes[item]){
          if (Array.isArray(this.fileMimeTypes[item])) {
            acceptStringArray.push(...this.fileMimeTypes[item]);
          } else {
            acceptStringArray.push(this.fileMimeTypes[item]);
          }
        }
      }
      this.acceptString = acceptStringArray.join(', ');
    }

  }


  @HostListener('dragover', ['$event'])
  public onDragOver(event) {
    event.preventDefault();
    event.stopPropagation();
  }

  @HostListener('dragleave', ['$event'])
  public onDragLeave(event) {
    event.preventDefault();
    event.stopPropagation();
  }

  @HostListener('drop', ['$event'])
  public onDrop(event) {
    event.preventDefault();
    event.stopPropagation();
    const files = event.dataTransfer.files;
    if (!this.multiple && files.length > 1) {
      return;
    }
    const fileEvent = {};
    set(fileEvent, 'target.files', files);
    this.onFileInput(fileEvent);
  }

  onFileInput(event): void {
    this.selectedFiles = event.target.files;
    this.progress = 0;
    if (this.selectedFiles) {
      if (!this.isBase64 && !this.returnAsFile) {

        this.loaderService.visibility = true;
      }
      const currentFiles = this.selectedFiles;
      const multipeValue = this.control.value || [];
      for (let i = 0; i < this.selectedFiles.length; i++) {
        const file: File | null = this.selectedFiles.item(i);

        /*Maximum allowed size in bytes
         5MB Example
         Change first operand(multiplier) for your needs*/
        const maxAllowedSize = this.fileSize * 1024 * 1024;

        if (file) {
          if (file.size <= maxAllowedSize) {
            if (!this.isValid(file)) {
              this.loaderService.visibility = false;
              this.alert.emitAlert({type: "danger", text: "File not supported"});
              this.clearFile();
              return;
            }

            if (this.returnAsFile) {
              this.blobUrl = URL.createObjectURL(file);

              this.control.setValue(file);
              this.control.markAsDirty();
              let fileSplit = file.name.split(".");
              let filename = fileSplit && fileSplit[0] ? fileSplit[0] : '';
              let extension = fileSplit && fileSplit[1] ? fileSplit[1] : '';
              this.fileInfoDetails = [
                {
                  "url": this.blobUrl,
                  "name_with_ext": file.name,
                  "name_without_ext": filename,
                  "ext": extension,
                  "mime": extension,
                  "type": this.fileType,
                  "size": file.size
                }
              ];
              this.onUpload.emit(file);
            } else if (this.isBase64) {
              this.toBase64(file).subscribe((encodedFile) => {
                this.onUpload.emit(encodedFile);
                if (this.multiple) {
                  multipeValue.push(encodedFile);
                  this.control.setValue(multipeValue);
                } else {
                  this.control.setValue(encodedFile);
                }
                this.control.markAsDirty();
              });
            } else {

              let fileType = this.fileType || Object.keys(this.extensionsByFileType).find(key => this.extensionsByFileType[key].join(",").toLowerCase().includes(file.name.split(".").pop()));
              let uploadPath = `upload/${fileType}`;
              if (this.path) uploadPath = this.path;


              let params = {};
              if (this.params) {
                let keys = Object.keys(this.params);
                if (keys.length) {
                  keys.forEach(param => {
                    params['params[' + param + ']'] = this.params[param];
                  });
                }
              }
              this.filesCount++;
              // either extension or file type check both maybe one is null
              // first extension then fileType of the uploaded file

              this.uploadService.upload(file, uploadPath, this.callbackPath && (typeof this.callbackPath === 'string' ? this.callbackPath : this.callbackPath[file.name.split(".").pop()] || this.callbackPath[fileType]), params).subscribe(
                (uploadEvent: any) => {
                  if (uploadEvent.type === HttpEventType.UploadProgress) {
                    this.progress = Math.round(100 * uploadEvent.loaded / uploadEvent.total);
                  } else if (uploadEvent instanceof HttpResponse) {
                    if (uploadEvent?.body?.response?.exception || uploadEvent?.body?.meta?.status === 'FAILED' || uploadEvent?.body?.response?.status === 'FAILED') {
                      this.progress = 0;
                      if ((currentFiles.length - 1) === i) {
                        this.loaderService.visibility = false;
                        this.clearFile();
                      }
                      if (uploadEvent?.body?.response && uploadEvent?.body?.response?.message) {
                        this.alert.emitAlert({type: "danger", text: uploadEvent.body.response.message});
                      } else {
                        this.alert.emitAlert({type: "danger", text: this.translate.instant('general.somethingWentWrong')});
                      }
                      this.currentFile = undefined;
                    } else {
                      this.currentFile = uploadEvent?.body?.response?.thumblink;
                      this.onUpload.emit(uploadEvent.body);
                      if (uploadEvent.body.response.path) {
                        if (this.multiple) {
                          if (this.returnFileWithInfo) {
                            multipeValue.push({
                              path: uploadEvent.body.response.path,
                              remote_name: uploadEvent.body.response.filename,
                              ...this.getFileInfo(file)
                            });
                          } else {
                            multipeValue.push(uploadEvent.body.response.path);
                          }
                          this.control.setValue(multipeValue);
                        } else {
                          if (this.returnFileWithInfo) {

                            this.control.setValue({
                              path: uploadEvent.body.response.path,
                              remote_name: uploadEvent.body.response.filename,
                              ...this.getFileInfo(file)
                            });
                          } else {
                            this.control.setValue(uploadEvent.body.response.path);
                          }
                        }
                        this.control.markAsDirty();
                      }
                    }
                  }
                  this.filesCount--;
                },
                (err: any) => {
                  this.filesCount--;
                  this.progress = 0;
                  if ((currentFiles.length - 1) === i) {
                    this.loaderService.visibility = false;
                    this.clearFile();
                  }
                  if (err.error && err.error.message) {
                    this.alert.emitAlert({type: "danger", text: err.error.message});
                  } else {
                    this.alert.emitAlert({type: "danger", text: this.translate.instant('general.somethingWentWrong')});
                  }
                  this.currentFile = undefined;
                });

            }
          } else {
            this.loaderService.visibility = false;
            this.alert.emitAlert({ type: "warning", text: this.fileSizeErrorMessage });
            this.clearFile();
            return;
          }
        }
      }
      this.selectedFiles = undefined;
    }
  }

  getFileInfo(file: File): any {
    let fileSplit = file.name.split(".");
    let extension = fileSplit.pop() || ''; // Get and remove the last element
    let filename = fileSplit.join('.'); // Join the remaining elements
    return {
      name_with_ext: file.name,
      name_without_ext: filename,
      ext: extension,
      mime: extension,
      type: this.fileType,
      size: file.size
    };
  }

  delete() {
    this.currentFile = undefined;
    this.control.setValue('');
    this.clearFile();
    if(this.blobUrl){
      URL.revokeObjectURL(this.blobUrl);
    }
    this.onDelete.emit();
    this.control.markAsDirty();
  }

  deleteFile(idx: number): void {
    remove(this.control.value, (i, _idx) => {
      return _idx === idx;
    });
    this.control.setValue(this.control.value);
    this.onDelete.emit(this.control.value);
    this.clearFile();
    this.control.markAsDirty();
  }

  getObjectData(file) {
    let result = false;
    if (this.fileInfoDetails) {
      result = this.fileInfoDetails.find(obj => {
        return obj.url === file
      });
    }
    return result;
  }

  fileInfo() {
    if(this.isBase64) this.loaderService.visibility = false;
    else if (this.returnFileWithInfo) {
      this.loaderService.visibility = false;
      this.fileInfoDetails = [...(this.currentFile || [])];
    } else {
      let stringArr = JSON.stringify(this.currentFile);
      if (this.currentFile && this.currentFile != undefined && stringArr != '[]') {
        this.loaderService.visibility = true;
        this.makeCall.postCall('file/fileinfo', {
          urls: this.currentFile,
        }, { updateIsLoading: false }).subscribe(data => {
          this.fileInfoDetails = data;
          this.loaderService.visibility = false;
        });
      }
    }
  }

  isObject(val): boolean {
    return typeof val === 'object';
  }

  download(file) {
    let downloadLink = "api/file/download?url=" + encodeURIComponent(file);

    if (this.blobUrl) {
      downloadLink = file;
    }
    FileSaver.saveAs(downloadLink, this.getFileName(file));
  }
  downloadviaPath(file) {
    const path = get (file , 'path');
    let downloadLink = 'api/file/download?url=' + path;

    if (this.blobUrl) {
      downloadLink = path;
    }
    FileSaver.saveAs(downloadLink, this.getFileName(path));
  }

  isString(file): boolean {
    return isString(file);
  }

  getFileLinkParams(link: string): any {
    return (get(link.split('?'), '1') || '')
      .split('&').reduce((memo, param) => ({
        ...memo,
        [param.split('=')[0]]: param.split('=')[1]
      }), {});
  }

  checkType(file, type): boolean {
    let ifImage = false;
    let objectData = this.getObjectData(file);
    if (this.fileInfoDetails && objectData) {
      ifImage = objectData['type'] === type;
    }
    return ifImage;
  }

  getFileName(file): string {
    let nameWithoutExt = '';
    let objectData = this.getObjectData(file);
    if (this.fileInfoDetails && objectData) {
      nameWithoutExt = objectData['name_without_ext'];
    } else {
      nameWithoutExt = get(file, 'name_without_ext') || '';
    }
    return nameWithoutExt;
  }

  getFileIconName(file): string {
    let ext = '';
    let objectData = this.getObjectData(file);
    if (this.fileInfoDetails && objectData) {
      ext = objectData['ext'];
    } else {
      ext = get(file, 'ext') || '';
    }
    return ext?.toLowerCase();
  }

  getFileSize(file): string {
    let size = 0;
    let objectData = this.getObjectData(file);
    if (this.fileInfoDetails && objectData) {
      size = parseInt(objectData['size']);
    }
    if (size === 0) return '0 Bytes';

    const k = 1024;
    const dm = 2;
    const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];

    const i = Math.floor(Math.log(size) / Math.log(k));

    return parseFloat((size / Math.pow(k, i)).toFixed(dm)) + ' ' + sizes[i];
  }


private isValid(file: File) {
    let isValid = true;
    const extension = /(?:\.([^.]+))?$/.exec(file.name)[0].substring(1)?.toLowerCase();
    const mimeType = file.type;

    const validExtensions = this.extensions;
    // extensions array or file type if extension array is null take from file type
    if (validExtensions) {
        isValid = validExtensions.includes(extension);
        if (!isValid && Array.isArray(this.fileMimeTypes[this.fileType])) {
            isValid = this.fileMimeTypes[this.fileType].includes(mimeType);
        }
    } else {
        isValid = this.fileMimeTypes[this.fileType]?.split("/*")[0] === mimeType.split("/")[0];
    }

    return isValid;
}

  toBase64(file: File): Observable<any> {
    return new Observable((observe) => {
      const reader = new FileReader();
      reader.onloadend = () => {
        // use a regex to remove data url part
        const base64String = (reader.result as string)
          .replace('data:', '')
          .replace(/^.+,/, '');
        const fileNameParts = file.name.split('.');
        observe.next({
          encoded_content: base64String,
          type: file.type,
          extension: fileNameParts.pop(),
          file_name: fileNameParts.join('.'),
        });
        observe.complete();
      };
      reader.readAsDataURL(file);
    });
  }

  @ViewChild("videoPlayer", { static: false }) videoplayer: ElementRef;
  toggleVideo() {
    this.videoplayer.nativeElement.play();
  }

  clearFile(): void {
    if (this.fileInput?.nativeElement) {
      try {
        this.fileInput.nativeElement.value = '';
        if (this.fileInput.nativeElement.value) {
          this.fileInput.nativeElement.type = "text";
          this.fileInput.nativeElement.type = "file";
        }
      } catch (e) {
      }
    }
  }

  ngOnDestroy() {
    if (this.blobUrl) {
      URL.revokeObjectURL(this.currentFile.toString());
    }
  }

}
