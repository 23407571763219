import { GeneralConfigService, RouteService } from 'foo-framework';
import { RouterService, routes } from './services/router.service';
import { Component, OnInit, Inject } from '@angular/core';
import {
  Router,
  NavigationEnd,
  ActivatedRoute,
  NavigationStart,
} from '@angular/router';
import { DOCUMENT } from '@angular/common';
import { CanonicalService, TokenStorage, AlertService } from 'foo-framework';
import { Title } from '@angular/platform-browser';
import { filter, map, mergeMap } from 'rxjs/operators';
import { get } from 'lodash';
@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent implements OnInit {
  staticAlertClosed = false;
  subscribeAlert: any;
  subscribegEvent: any;
  alertMessage: string;
  alertType: string;
  showAlert: boolean = false;
  showBackground = false;
  _isrtl = true;
  listingEllipsisConfig: boolean = true;

  get isrtl(): boolean {
    return this._isrtl;
  }

  set isrtl(value: boolean) {
    this.dom.querySelector('body').dir = value ? 'rtl': 'ltr';
    this.dom.querySelector('body').className = value ? ' page-body direction-rtl': 'page-body direction-ltr';
    this._isrtl = value;
  }
  constructor(
    @Inject(DOCUMENT) private dom,
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private canonicalService: CanonicalService,
    private titleService: Title,
    private tokenStorage: TokenStorage,
    private routerService: RouterService,
    private routeService: RouteService,
    private alert: AlertService,
    private generalConfigService: GeneralConfigService,
    @Inject('Permissions') private permission: any
  ) {
    if (window['user'] === 'null') {
      this.tokenStorage.setUser('null');
    }

    router.events.subscribe((event) => {
      if (event instanceof NavigationStart) {
        const urlTree = this.router.parseUrl(event.url);
        const urlWithoutParams = (
          (get(urlTree.root.children, 'primary.segments') as any) || []
        )
          .map((it) => it.path);
        this.permission.viewPermission(
          urlWithoutParams[urlWithoutParams.length - 1]
        );
      }
    });

    this.routeService.handleOnReload();
    router.events.subscribe((event) => {
      if (event instanceof NavigationStart) {
        this.routeService.handleRoute(event.url);
        return;
      }
    });

    if (
      !this.routerService.initiatedRoutes /* && this.tokenStorage.getUser() */
    ) {
      this.routerService.init();
    }
  }

  public setTitle(newTitle: string) {
    const conf = window['configuration'] ? JSON.parse(window['configuration']) : [];
    let configTitle = conf && conf['title'] ? conf['title'] : '';
    if(configTitle && newTitle){
      configTitle += " - ";
    }
    if( newTitle) {
      configTitle += newTitle;
    }
    this.titleService.setTitle(configTitle);
  }
  ngOnInit() {
    const listingEnvConfig = this.generalConfigService.getConfig().listingComponentConfig?.ellipsisEffect;
    this.listingEllipsisConfig = listingEnvConfig !== undefined ? listingEnvConfig : true;
    
    const hostName = this.dom.location.origin;
    //handler for alert boxes
    this.subscribeAlert = this.alert.getAlert().subscribe((item) => {
      this.alertMessage = item.text;
      this.alertType = item.type;
      this.showAlert = true;
      setTimeout(() => {
        this.showAlert = false;
      }, 5000);
    });

    this.router.events
      .pipe(
        filter((event) => event instanceof NavigationEnd),
        map(() => this.activatedRoute),
        map((route) => {
          while (route.firstChild) {
            route = route.firstChild;
          }
          return route;
        }),
        filter((route) => route.outlet === 'primary'),
        mergeMap((route) => route.data)
      )
      .subscribe((event) => {
        this.setTitle(event.title);
        // Set Canonical URL
        if (event.canonical) {
          this.canonicalService.updateCanonicalUrl(hostName + event.canonical);
        } else {
          this.canonicalService.updateCanonicalUrl(hostName + this.router.url);
        }
        //this.showBackground = event.skylineBackground;
      });

    //setting the lang RTL
    if(localStorage.getItem("curLang") && localStorage.getItem("curLang") == "ar"){
      this.isrtl = true;
    }else{
      localStorage.setItem("curLang", "en");
      this.isrtl = false;
    }
  }

  onActivate(elementRef) {
    elementRef.LangChangedEvent.subscribe((event) => {
      if (event == 'ar') {
        this.isrtl = true;
      } else {
        this.isrtl = false;
      }
      localStorage.setItem('curLang', event);
    });
  }

  randomString(length, chars) {
    let result = '';
    for (let i = length; i > 0; --i) {
      result += chars[Math.floor(Math.random() * chars.length)];
    }
    return result;
  }
}
