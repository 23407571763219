import {Component, OnInit, Output, EventEmitter} from '@angular/core';
import {UntypedFormControl, UntypedFormGroup, Validators} from '@angular/forms';
import {ActivatedRoute, Router} from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { DataStorageService } from '../../services/data-storage.service';
import { AlertService } from '../../services/alert.service';
import { LoaderService } from '../../services/loader/loader.service';
import { MakeCallService } from '../../services/makecall.service';
import moment from 'moment';
import {CustomValidatorsService} from "../../services/custom-validators.service";
import {TokenStorage} from '../../services/token-storage.service';
import {GeneralConfigService} from '../../services/general-config.service';


@Component({
  selector: 'app-forgot-password',
  templateUrl: './forgot-password.component.html',
  styleUrls: ['./forgot-password.component.scss']
})
export class ForgotPasswordComponent implements OnInit {
  showLang = false;
  isrtl = false;
  returnUrl = '';
  recaptchaEnabled: boolean = !!window['google_recaptcha_guest'];
  showCopyright;
  showDesignedDevelopedByFoo;
  constructor(private _router: Router,
              private translate: TranslateService,
              private DataStorage: DataStorageService,
              private activatedRoute: ActivatedRoute,
              private makecall: MakeCallService,
              private alert: AlertService,
              private customValidators: CustomValidatorsService,
              private tokenStorage: TokenStorage,
              private generalConfigService: GeneralConfigService,
              private loaderService: LoaderService) {
    translate.setDefaultLang(this.tokenStorage.getCurrentLanguage());
  }
  emailForm = new UntypedFormGroup({
    email: new UntypedFormControl('', [Validators.required, this.customValidators.email])
  });
  data: any;
  curLang: string;
  configuration = window['configuration'] ? JSON.parse(window['configuration']) : null;
  logo = this.configuration && this.configuration['login_logo'] ? this.configuration['login_logo'] :
    this.configuration && this.configuration['logo'] ? this.configuration['logo'] : null;
  projectName = this.configuration ? this.configuration['title'] :'';
  currentYear = moment().year();
  @Output() LangChangedEvent = new EventEmitter<string>();
  ngOnInit(): void {
    const showCopyrightVal = this.generalConfigService.getConfigProperty('commonConfig')?.showCopyright;
    this.showCopyright = showCopyrightVal !== undefined ? showCopyrightVal : true;
    const showDesignedDevelopedByFooVal = this.generalConfigService.getConfigProperty('commonConfig')?.showDesignedDevelopedByFoo;
    this.showDesignedDevelopedByFoo = showDesignedDevelopedByFooVal !== undefined ? showDesignedDevelopedByFooVal : true;

    this.returnUrl = this.activatedRoute.snapshot.queryParams['returnUrl'];

    this.showLang = window['showlang'] ? JSON.parse(window['showlang']) : null;
    if (this.tokenStorage.getCurrentLanguage() == 'ar') {
      this.isrtl = true;
    }
    this.curLang = this.tokenStorage.getCurrentLanguage();
  }
  get f() { return this.emailForm.controls; }

  onSubmit() {
    if (this.emailForm.valid) {
    this.loaderService.visibility = true;

    this.makecall.postCall("auth/forgotpin", {email: this.f.email.value}, null, { googleRecaptchaEnabled: this.recaptchaEnabled}).subscribe(resp => {
        if (resp["status"] == "OK") {
          this.DataStorage.storage = {
            type: "success",
            text: this.translate.instant('general.sentemail')
          };
          if (this.returnUrl) {
            this._router.navigate(['/login'], {queryParams: {returnUrl: this.returnUrl}});
          } else {
            this._router.navigate(['/login']);
          }
        } else {
          this.alert.emitAlert({type: "danger", text: resp["message"]});
        }
      },
      error => {
        this.alert.emitAlert({type: 'danger', text: this.translate.instant('general.somethingWentWrong')});
      });
    }
  }

  goBack(){
    if(this.returnUrl){
      this._router.navigate(['/login'], { queryParams: { returnUrl: this.returnUrl } });
    }else{
      this._router.navigate(['/login']);
    }
  }

  useLanguage() {
    var language = 'en';
    this.isrtl = false;
    if (this.tokenStorage.getCurrentLanguage() == 'en') {
      language = 'ar';
      this.isrtl = true;
    }
    this.translate.use(language);
    this.LangChangedEvent.emit(language);
    this.tokenStorage.setCurrentLanguage(language);

  }
}
