import { Component, OnInit, Output, EventEmitter } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { MakeCallService } from '../../services/makecall.service';
import { AlertService } from '../../services/alert.service';
import {TranslateService} from "@ngx-translate/core";
import moment from 'moment';
import {UntypedFormControl, UntypedFormGroup, Validators} from "@angular/forms";
import { LoaderService } from '../../services/loader/loader.service';
import { keyBy } from 'lodash';
import { MatDialog } from '@angular/material/dialog';
import { ConfirmDialogComponent } from './confirm-dialog/confirm-dialog.component';
import { TokenStorage } from '../../services/token-storage.service';
import { AuthenticateService } from '../../services/authenticate.service';
import { Subscription } from 'rxjs';
import {GeneralConfigService} from '../../services/general-config.service';

@Component({
  selector: 'app-reset-password',
  templateUrl: './reset-password.component.html',
  styleUrls: ['./reset-password.component.scss']
})
export class ResetPasswordComponent implements OnInit {
  curLang: string;
  hasSymbols = false;
  hasNumbers = false;
  hasLowercase = false;
  hasUppercase = false;
  hasLength = false;
  resetForm = new UntypedFormGroup({
    new_pin: new UntypedFormControl('', [Validators.required]),
    repeat_new_pin: new UntypedFormControl('', [Validators.required])
  });
  @Output() LangChangedEvent = new EventEmitter<string>();
  email: string;
  code: string;
  type: string;
  configuration = JSON.parse(window['configuration']);
  logo = this.configuration && this.configuration['login_logo'] ? this.configuration['login_logo'] : this.configuration['logo'];
  projectName = this.configuration ? this.configuration['title'] :'';
  passConfig: any;
  charNumber =  0;
  currentYear = moment().year();
  showLang = false;
  isrtl = false;
  pagetitle='auth.resetPassword';
  pageSubTitle = '';
  btntitle = 'auth.resetPassword';
  private settingSubTitle: Subscription;
  recaptchaEnabled: boolean = !!window['google_recaptcha_guest'];
  showCopyright;
  showDesignedDevelopedByFoo;

  public constructor(
    private router: Router,
    private makecall: MakeCallService,
    private alert: AlertService,
    private route: ActivatedRoute,
    private translate: TranslateService,
    private loaderService: LoaderService,
    private dialog: MatDialog,
    private tokenStorage: TokenStorage,
    private activatedRoute: ActivatedRoute,
    private generalConfigService: GeneralConfigService,
    private authenticateService: AuthenticateService) {
    translate.setDefaultLang(this.tokenStorage.getCurrentLanguage());
  }

  ngOnInit(): void {
    const showCopyrightVal = this.generalConfigService.getConfigProperty('commonConfig')?.showCopyright;
    this.showCopyright = showCopyrightVal !== undefined ? showCopyrightVal : true;
    const showDesignedDevelopedByFooVal = this.generalConfigService.getConfigProperty('commonConfig')?.showDesignedDevelopedByFoo;
    this.showDesignedDevelopedByFoo = showDesignedDevelopedByFooVal !== undefined ? showDesignedDevelopedByFooVal : true;

    this.showLang = JSON.parse(window['showlang']);
    this.curLang = this.tokenStorage.getCurrentLanguage();
    this.email = this.route.snapshot.paramMap.get('email');
    this.code = this.route.snapshot.paramMap.get('code');
    this.type = this.route.snapshot.paramMap.get('type');

    if ((this.router?.url)?.includes('/password/setpasswordview/')) {
      this.type = 'set';
    }

    if(this.type !==null && this.type !== undefined){
      if(this.type == 'set'){
        this.pagetitle='auth.setPassword';
        this.btntitle = 'auth.setPassword';
      }
    }

    const subTitleTranslateKey = 'auth.setPasswordSubTitle';
    this.settingSubTitle = this.translate.get(subTitleTranslateKey).subscribe((res: string) => {
      if(res != '' && res != subTitleTranslateKey){
        this.pageSubTitle = subTitleTranslateKey;
      }
    });

    if(this.code && this.email){
      this.loaderService.visibility=true;
      this.makecall.postCall("auth/checkpasscode",{'code':this.code,'email':this.email}, null, {googleRecaptchaEnabled: this.recaptchaEnabled}).subscribe(resp=> {
          this.loaderService.visibility=false;
          if(resp["status"]=="OK"){
            this.getPassConfig();
          }else{
            this.alert.emitAlert({type:"danger", text: resp["message"]});
            this.router.navigate(['/login']);
          }
        },
        error=>{
          this.loaderService.visibility=false;
          this.alert.emitAlert({type:"danger", text: this.translate.instant('general.somethingWentWrong')});
          this.router.navigate(['/login']);
        });
    }else{
      this.router.navigate(['/login']);
    }

  }

  getPassConfig(){
    this.loaderService.visibility=true;
    this.makecall.postCall("auth/passconfig",{
      "email": this.email
    }, null, { googleRecaptchaEnabled: this.recaptchaEnabled}).subscribe(resp=> {
        this.loaderService.visibility=false;
        if(resp["status"]=="OK"){
          this.passConfig = keyBy(resp['configuration'], 'key');
          this.charNumber = this.passConfig['password_length'] && this.passConfig['password_length'].value ? this.passConfig['password_length'].value : 0;
        }else{
          this.alert.emitAlert({type:"danger", text: resp["message"]});
        }
      },
      error=>{
        this.loaderService.visibility=false;
        this.alert.emitAlert({type:"danger", text: this.translate.instant('general.somethingWentWrong')});
        this.router.navigate(['/login']);
      });
  }
  get f() { return this.resetForm.controls; }

  onSubmit() {
    if (this.resetForm.valid) {
      this.loaderService.visibility = true;
      if (this.checkPass()) {
        this.makecall.postCall("auth/resetpass", {
          "password": this.f.new_pin.value,
          "email": this.email,
          "code": this.code
        }, null, {googleRecaptchaEnabled: this.recaptchaEnabled}).subscribe(resp => {
            if (resp["status"] == "OK") {
              this.alert.emitAlert({type: "success", text: resp["message"]});
              if (this.tokenStorage.getUser() && this.tokenStorage.getUser() !== 'undefined' && this.tokenStorage.getUser() !== 'null') {
                let componentConfig = {
                  title: this.translate.instant('general.confirmDialogTitle'),
                  text: this.translate.instant('general.confirmDialogBody')
                };
                const dialogRef = this.dialog.open(ConfirmDialogComponent, {
                  width: '500px',
                  panelClass: ['app-custom-dialog-container'],
                  backdropClass: 'app-customer-backdrop',
                  data: componentConfig
                });
                dialogRef.afterClosed().subscribe(result => {
                  if (result === 'submit') {
                    this.loaderService.visibility = true;
                    this.authenticateService.logout().subscribe(
                      (data) => {
                        if (data["status"] === 'OK') {
                          this.tokenStorage.clear();
                          this.activatedRoute.snapshot.queryParams={};
                          window.location.href = (window['prefix'] ? `/${window['prefix']}` : '') + '/login';
                          this.authenticateService.cancelPendingRequests();
                        } else {
                          this.loaderService.visibility = false;
                          this.alert.emitAlert({type: "danger", text: data["message"]});
                        }
                      },
                      error => {
                        this.loaderService.visibility = false;
                        this.alert.emitAlert({type: "danger", text: error["message"]});
                      });
                } else {
                  this.router.navigate(['/login']);
                  }
                });
              } else {
                  this.router.navigate(['/login']);
              }
            } else {
              this.alert.emitAlert({type: "danger", text: resp["message"]});
            }
            this.loaderService.visibility = false;
          },
          error => {
            this.loaderService.visibility = false;
            this.alert.emitAlert({type: 'danger', text: this.translate.instant('general.somethingWentWrong')});
          });
      } else {
        this.loaderService.visibility = false;
        if(this.allDoneExceptPasswordMismatch()) {
          this.alert.emitAlert({type: "danger", text: this.translate.instant('auth.passwordMismatch')});
        } else {
          this.alert.emitAlert({type: "danger", text: this.translate.instant('auth.PasswordDoesNotMeetTheMinimumRequirements')});
        }
      }
    }
  }

  allDoneExceptPasswordMismatch() {
    if ((this.f.new_pin.value != this.f.repeat_new_pin.value) && this.hasSymbols && this.hasNumbers && this.hasLowercase && this.hasUppercase && this.hasLength) {
      return true;
    }
  }

  checkPass(){
    return (this.f.new_pin.value === this.f.repeat_new_pin.value
      && this.hasSymbols
      && this.hasNumbers
      && this.hasLowercase
      && this.hasUppercase
      && this.hasLength);
  }


  passModify() {
    if (this.passConfig && this.passConfig["password_length"] && this.f.new_pin.value.length < Number(this.passConfig["password_length"].value)) {
      this.hasLength = false;
    } else {
      this.hasLength = true;
    }
    let re = new RegExp(/[-!@$%^&*()_+|~=`{}\[\]:";'<>?,.\/]/);
    if (this.passConfig && this.passConfig["password_symbols"] && this.passConfig["password_symbols"].value == 1 && !re.test(this.f.new_pin.value)) {
      this.hasSymbols = false;
    } else {
      this.hasSymbols = true;
    }
    re = new RegExp(/[0-9]/);
    if (this.passConfig && this.passConfig["password_numbers"] && this.passConfig["password_numbers"].value == 1 && !re.test(this.f.new_pin.value)) {
      this.hasNumbers = false;
    } else {
      this.hasNumbers = true;
    }
    re = new RegExp(/[a-z]/);
    if (this.passConfig && this.passConfig["password_lowercase"] && this.passConfig["password_lowercase"].value == 1 && !re.test(this.f.new_pin.value)) {
      this.hasLowercase = false;
    } else {
      this.hasLowercase = true;
    }
    re = new RegExp(/[A-Z]/);
    if (this.passConfig && this.passConfig["password_uppercase"] && this.passConfig["password_uppercase"].value == 1 && !re.test(this.f.new_pin.value)) {
      this.hasUppercase = false;
    } else {
      this.hasUppercase = true;
    }

  }
  useLanguage() {
    var language = 'en';
    this.isrtl = false;
    if (this.tokenStorage.getCurrentLanguage() == 'en') {
      language = 'ar';
      this.isrtl = true;
    }
    this.translate.use(language);
    this.LangChangedEvent.emit(language);
    this.tokenStorage.setCurrentLanguage(language);
  }
}
