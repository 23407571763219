import { Injectable, Injector } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { AlertService, MakeCallService, ParserService, ReplaceSeparatorAndCapitalizePipe, TokenStorage, CurrencyService, DomainService } from 'foo-framework';
import { TransactionDetailsService, TranslationService } from 'foo-framework/shared-modules';
import * as _ from 'lodash';
import { DecimalPipe } from '@angular/common';
import { Router } from '@angular/router';

@Injectable({
  providedIn: 'root'
})
export class CustomTransactionDetailsService extends TransactionDetailsService {

  templatetypes = [];
  branchTypesById = [];
  curLang: string;

  constructor(
    protected injector: Injector,
    protected translate: TranslateService,
    protected makecall: MakeCallService,
    protected alert: AlertService,
    protected parser: ParserService,
    protected translationService: TranslationService,
    protected decimalPipe: DecimalPipe,
    protected tokenStorage: TokenStorage,
    private router: Router,
    public domain: DomainService,
    protected replaceSeparatorAndCapitalizePipe: ReplaceSeparatorAndCapitalizePipe,
    protected currencyService: CurrencyService) {
    super(injector, translate, makecall, alert, parser, translationService, decimalPipe, tokenStorage, replaceSeparatorAndCapitalizePipe, currencyService)
    this.curLang = localStorage.getItem('curLang');
  }

  transformData(data, category, roles) {
    if (data.length > 0) {
      this.getCustomValuesData(data);
      if (data[0].action === 'delete') {
        return this.getDeleteInfo(data);
      }
      if (data[0].action === 'edit' || data[0].action === 'add') {
        data[0].description_transaction = this.getDescriptionItem(data[0], category);
      }
      if (category === 'cmsUsers' && data[0].action === 'view') {
        return { pending_transaction: data[0] };
      } else if (category === 'idvUsers') {
        return this.getPendingCMSUserData(data, roles);
      } else if (category === 'merchant') {
        return this.getPendingMerchantData(data, category);
      } else if (category === 'idv') {
        return this.getPendingIdvOperationResultsdData(data);
      }
    }

    return super.transformData(data, category, roles);
  }
  getDeleteInfo(data) {
    const dataToSend = {
      pending_transaction: {
        description_transaction: data[0].description_transaction || '',
        transaction_details: []
      }
    };
    if (data[0].key === "merchant_app_config") {
      const item = data[0].feature_item;
      if (item?.name) {
        dataToSend.pending_transaction.transaction_details.push({
          column_name: this.translate.instant('approval.name'),
          old_value: item.name,
          new_value: this.translate.instant('approval.notAvailable')
        });
        return dataToSend;
      }
      if (item?.app?.name) {
        dataToSend.pending_transaction.transaction_details.push({
          column_name: this.translate.instant('approval.name'),
          old_value: item?.app?.name,
          new_value: this.translate.instant('approval.notAvailable')
        });
        return dataToSend;
      }
    }
    return super.getDeleteInfo(data);
  }

  getPendingCMSUserData(transactions, roles) {
    const formatedDataToSend = {
      pending_transaction: {
        description_transaction: transactions[0].description_transaction || '',
        transaction_details: []
      }
    };
    const dataToSend = super.getPendingCMSUserData(transactions, roles);
    formatedDataToSend.pending_transaction.description_transaction = dataToSend?.pending_transaction?.description_transaction;
    dataToSend?.pending_transaction?.transaction_details.forEach(row => {
      if (row?.new_value !== row?.old_value) {
        formatedDataToSend.pending_transaction.transaction_details.push(row);
      }
    });
    return formatedDataToSend;
  }
  getPendingMerchantData(transactions, category) {
    const dataToSend = {
      pending_transaction: {
        description_transaction: transactions[0].description_transaction || '',
        transaction_details: []
      }
    };

    if (transactions && transactions.length) {
      const filteredTransactions = transactions.filter(t => t?.key === category);
      if (transactions[0]?.action === 'add' && filteredTransactions.length) {
        for (const transaction of filteredTransactions) {
          for (const element of transaction?.transaction_details) {
            if (element?.old_value !== element?.new_value) {
              if (element.column_name != 'image' && element.column_name != 'acquirer_id') {
                if (element.column_name === 'active') {
                  dataToSend.pending_transaction.transaction_details.push({
                    column_name: element.column_name,
                    old_value: element.old_value,
                    new_value: element?.new_value === '-1' || element?.new_value === -1 ? this.translate.instant('approval.no') : this.translate.instant('approval.yes')
                  });
                } else {
                  dataToSend.pending_transaction.transaction_details.push({
                    column_name: element.column_name,
                    old_value: element.old_value,
                    new_value: element.new_value,
                  });
                }
              }
            }
          }
          var last = dataToSend.pending_transaction.transaction_details.pop();
          last.new_value_class = '';
          last.old_value_class = '';
          last.colmn_name_class = 'border-bottom';
          dataToSend.pending_transaction.transaction_details.push(last);
        }
      } else {
        for (const element of transactions[0].transaction_details) {
          if (element?.old_value !== element?.new_value) {
            dataToSend.pending_transaction.transaction_details.push({
              column_name: element.column_name,
              old_value: element.old_value,
              new_value: element.new_value
            });
          }
        }
      }
    }
    return dataToSend;
  }

  getDescriptionTransactionActiveInactiveCmsUsersActions(transactions) {
    if (transactions[0].feature_item && transactions[0].feature_item.email) {
      let arrayOfObject = [
        {
          key: this.translate.instant('approval.fullname'),
          value: transactions[0]?.feature_item?.profile?.fullname
        },
        {
          key: this.translate.instant('approval.email'),
          value: transactions[0]?.feature_item?.email
        }
      ];
      return this.transformJsonToString(arrayOfObject);
    }
  }


  setCategoryKeyName(transactions) {
    if (this.checkIfKeyExist(transactions, 'merchant')) {
      return 'merchant';
    }
    return super.setCategoryKeyName(transactions);
  }

  getPendingIdvOperationResultsdData(transactions) {
    const dataToSend = {
      pending_transaction: {
        description_transaction: transactions[0].description_transaction || '',
        transaction_details: []
      }
    };

    if (transactions && transactions.length) {
      let arrayOfObject = [];
      for (const element of transactions[0].transaction_details) {
        if (element.column_name === 'cms_approval_status') {
          const objectsToAdd = [
            {
              key: this.translate.instant('approval.action'),
              value: transactions[0]?.extra?.action || ''
            },
            {
              key: this.translate.instant('approval.idvAccountName'),
              value: transactions[0]?.extra?.custom_item?.user?.fullname || ''
            },
            {
              key: this.translate.instant('approval.section'),
              value: transactions[0]?.extra?.section || ''
            },
            {
              key: this.translate.instant('approval.idvAccountId'),
              value: transactions[0]?.feature_item?.user?.profile?.user_id || ''
            },
            {
              key: this.translate.instant('approval.idvAccountName'),
              value: transactions[0]?.feature_item?.user?.profile?.fullname || ''
            }
          ];

          arrayOfObject.push(...objectsToAdd.filter(obj => obj.value));
          dataToSend.pending_transaction.transaction_details.push({
            column_name: element.column_name,
            old_value: this.getCmsApprovalStatus(element.old_value),
            new_value: this.getCmsApprovalStatus(element.new_value),
          });
        } else if (element.column_name === 'status') {
          dataToSend.pending_transaction.transaction_details.push({
            column_name: element.column_name,
            old_value: this.getCmsApprovalStatus(element.old_value, true),
            new_value: this.getCmsApprovalStatus(element.new_value, true),
          });
        } else {
          if (element.column_name !== 'cms_action_by' && element.column_name !== 'cms_action_at' && element.column_name !== 'id' && element.column_name != 'result_status') {
            dataToSend.pending_transaction.transaction_details.push({
              column_name: element.column_name,
              old_value: element.old_value,
              new_value: element.new_value
            });
          }
        }
      }
      let ivs = this.getColumn(transactions[0].transaction_details, 'id')?.new_value;
      let id = this.getColumn(transactions[0].transaction_details, 'record_id')?.new_value;
      if (ivs && id) {
        const params = {
          id: id,
          ivs: ivs
        };
        const link = this.router.serializeUrl(this.router.createUrlTree([this.domain.getPrefixedURL('/idv-users/idv-details')], { queryParams: params }));
        arrayOfObject.push({
          key: this.translate.instant('idverification.view_details'),
          value: '<a href=' + link + ' target="_blank">' + this.translate.instant('idverification.view') + '</a>'
        })
      }
      dataToSend.pending_transaction.description_transaction += this.transformJsonToString(arrayOfObject);
    }
    return dataToSend;
  }

  getCmsApprovalStatus(value, status = false) {
    let result = '';
    switch (Number(value)) {
      case 1:
        result = status ? this.translate.instant('approval.active') : this.translate.instant('approval.approved');
        break;
      case -1:
        result = this.translate.instant('approval.rejected')
        break;
      case -2:
        result = this.translate.instant('approval.failed')
        break;

      default:
        result = this.translate.instant('approval.pending')
        break;
    }

    return result;
  }
}