import { Component, EventEmitter, OnInit, Output, ContentChild, TemplateRef } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { AuthenticateService } from '../../services/authenticate.service';
import { ActivatedRoute, Router } from '@angular/router';
import { Credential } from '../../interfaces/credential';
import { TokenStorage } from '../../services/token-storage.service';
import { TranslateService } from '@ngx-translate/core';
import { DataStorageService } from '../../services/data-storage.service';
import { AlertService } from '../../services/alert.service';
import { LoaderService } from '../../services/loader/loader.service';
import { DomSanitizer } from '@angular/platform-browser';
import { MakeCallService } from '../../services/makecall.service';
import { PermissionsService } from '../../services/permissions.service';
import { RouteService } from '../../services/route.service';
import { GeneralConfigService } from '../../services/general-config.service';
import moment from 'moment';
import { get } from 'lodash';
@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss'],
})
export class LoginComponent implements OnInit {
  @ContentChild('custombuttons') customButtons: TemplateRef<any>;

  loginForm = new UntypedFormGroup({
    username: new UntypedFormControl('', Validators.required),
    pin: new UntypedFormControl('', Validators.required),
    rememberMe: new UntypedFormControl(''),
  });
  isrtl = false;
  showUaePass = false;
  uaeCheckingToken;
  uaeError = false;
  credentials: Credential;
  code;
  @Output() LangChangedEvent = new EventEmitter<string>();
  configuration = window['configuration'] ? JSON.parse(window['configuration']) : null;
  logo;
  projectName;
  currentYear = moment().year();
  successFieldShow = false;
  successMessage = '';
  showLang = false;
  returnUrl = '';
  selfOnboardingCategoryKey;
  recaptchaEnabled: boolean = !!window['google_recaptcha_guest'];
  showCopyright;
  showDesignedDevelopedByFoo;
  browserCredentialsSave;
  constructor(
    private authenticateService: AuthenticateService,
    private _router: Router,
    private tokenStorage: TokenStorage,
    private loaderService: LoaderService,
    private DataStorage: DataStorageService,
    private translate: TranslateService,
    private sanitizer: DomSanitizer,
    private makecall: MakeCallService,
    private alert: AlertService,
    private activatedRoute: ActivatedRoute,
    private permission: PermissionsService,
    private generalConfigService: GeneralConfigService,
    private routesService: RouteService
  ) {
    translate.setDefaultLang(this.tokenStorage.getCurrentLanguage());
    this.projectName = this.configuration?.translations?.find(translation => {
      return translation?.lang === (this.tokenStorage.getCurrentLanguage() ? this.tokenStorage.getCurrentLanguage() : '');
    })?.['title'] || this.configuration?.['title'];
  }

  ngOnInit(): void {
    const showCopyrightVal = this.generalConfigService.getConfigProperty('commonConfig')?.showCopyright;
    this.showCopyright = showCopyrightVal !== undefined ? showCopyrightVal : true;
    const showDesignedDevelopedByFooVal = this.generalConfigService.getConfigProperty('commonConfig')?.showDesignedDevelopedByFoo;
    this.showDesignedDevelopedByFoo = showDesignedDevelopedByFooVal !== undefined ? showDesignedDevelopedByFooVal : true;

    const customPass = this.generalConfigService.getConfigProperty('loginConfig')?.browserCredentialsSave;
    this.browserCredentialsSave = customPass !== undefined ? customPass : true;

    this.logo = this.tokenStorage.getLoginLogo();
    this.returnUrl = this.activatedRoute.snapshot.queryParams['returnUrl'];
    document.body.classList.add('login-page-template');
    if (this.tokenStorage.getUser() && this.tokenStorage.getUser() !== 'undefined' && this.tokenStorage.getUser() !== 'null') {
      document.body.classList.remove('login-page-template');
      if (!this.selfOnboardingCategoryKey) {
        const userconf = window['userconfig'] ? JSON.parse(window['userconfig']) : [];
        if (get(userconf, 'default_state')) {
          this.loaderService.visibility = false;
          this._router.navigate([get(userconf, 'default_state')]);
        } else {
          this.loaderService.visibility = false;
          this._router.navigate(['/home']);
        }
      }
    } else {
      if (this.tokenStorage.getSelfOnboardingToken()) {
        this._router.navigate(['/self-onboarding']);
      }
    }

    this.showLang = window['showlang'] ? JSON.parse(window['showlang']) : false;
    if (this.tokenStorage.getCurrentLanguage() == 'ar') {
      this.isrtl = true;
    }
    //this.loaderService.visibility = false;
    if (this.DataStorage.storage && this.DataStorage.storage.text) {
      this.successFieldShow = true;
      this.successMessage = this.DataStorage.storage.text;
    }

    this.tokenStorage.setSelfOnboardingCategoryKey();

    this.selfOnboardingCategoryKey = this.tokenStorage.getSelfOnboardingCategoryKey();
  }

  // convenience getter for easy access to form fields
  get f() {
    return this.loginForm.controls;
  }

  onSubmit() {
    if (this.loginForm.valid) {
      this.loaderService.visibility = true;
      this.successFieldShow = false;

      this.credentials = {
        username: this.f.username.value,
        password: this.f.pin.value,
      };

      this.authenticateService
        .login(this.f.username.value, this.f.pin.value, this.f.rememberMe.value, this.recaptchaEnabled)
        .subscribe(
          (data) => {
            if (data['status'] === 'OK') {
              document.body.classList.remove('login-page-template');
              this.routesService.routesHistory = [];
              if (data['should_validate']) {
                this.DataStorage.storage = {
                  email: data['email'],
                  token: data['token'],
                  resend_counter: data["expireAfter"],
                  route: '/home',
                  dataUser: data['user'],
                  register_validation: true
                };
                if (this.returnUrl && !this.selfOnboardingCategoryKey) {
                  this._router.navigate(['/login-otp'], { queryParams: { returnUrl: this.returnUrl } });
                } else {
                  this._router.navigate(['/login-otp']);
                }
              } else if (data['should_reset']) {
                this._router.navigate(['/password/resetpasswordview/' + data['email'] + '/' + data['token']], { queryParams: { email: data['email'], code: data['token'] } });
              } else {

                let appStatus = data?.data?.status;
                let isSelfOnboarding = this.selfOnboardingCategoryKey && (appStatus == 'DRAFT' || appStatus == 'PENDING');

                if (isSelfOnboarding) {
                  if (appStatus == 'DRAFT') {
                    if (data?.data?.application_id) {
                      this.tokenStorage.setSelfOnboardingApplicationId('' + data?.data?.application_id);
                    }
                  }

                  if (appStatus == 'PENDING') {
                    if (data?.data?.category_answer_id) {
                      this.tokenStorage.setSelfOnboardingCategoryAnswerId('' + data?.data?.category_answer_id);
                    }
                  }

                  if (data?.data['token']) {
                    this.tokenStorage.setSelfOnboardingToken(data?.data?.token);
                  }
                }

                this.tokenStorage.setUser(JSON.stringify(data['user']));
                this.tokenStorage.setWebConfig(
                  JSON.stringify(data['configuration'])
                );
                this.tokenStorage.setUserRoles(JSON.stringify(data['role']));
                this.tokenStorage.setLanguages(JSON.stringify(data['languages']));

                window['user'] = JSON.stringify(data['user']);

                window['configuration'] = JSON.stringify(data['configuration']);
                window['role'] = JSON.stringify(data['role']);
                window['languages'] = JSON.stringify(data['languages']);
                window['appConfig'] = JSON.stringify(data['app_config']);
                this.routesService.afterLogin$.next(true);

                if (!isSelfOnboarding) {
                  window['menu'] = JSON.stringify(data['menu']);
                  window['defaultState'] = JSON.stringify(data["configuration"] && data["configuration"].default_state ? data["configuration"].default_state : '');
                  this.tokenStorage.setLastFailedLogin(data['user'].last_failed_login);
                  if (data['user'].lastlogin) {
                    this.tokenStorage.setLastLogin(JSON.stringify(data['user'].lastlogin));
                  }
                  if (this.returnUrl) {
                    this.routesService.handleOnReloadWithRoute(this.returnUrl);
                    this._router.navigateByUrl(this.returnUrl);
                  }
                  else {
                    this._router.navigate(['']);
                  }
                } else {
                  this._router.navigate(['/self-onboarding']);
                }
              }
            } else if (data['status'] === 'redirect') {
              if (data['redirectTo']) {
                window.location.href = data['redirectTo'];
              }
            } else {
              this.alert.emitAlert({ type: 'danger', text: data['message'] });
            }
          },
          (error) => {
            this.alert.emitAlert({ type: 'danger', text: error['message'] });
          }
        );
    }
  }

  useLanguage() {
    var language = 'en';
    this.isrtl = false;
    if (this.tokenStorage.getCurrentLanguage() == 'en') {
      language = 'ar';
      this.isrtl = true;
    }
    this.translate.use(language);
    this.LangChangedEvent.emit(language);
    this.tokenStorage.setCurrentLanguage(language);
  }

  forgotPass() {
    this.DataStorage.storage = {
      type: 'forgot-pass',
      route: '/forgot-password',
      title: 'general.forgotPassword',
    };

    if (this.returnUrl) {
      this._router.navigate(['/forgot-password'], { queryParams: { returnUrl: this.returnUrl } });
    } else {
      this._router.navigate(['/forgot-password']);
    }
  }

  goToSelfOnboardingSignup() {
    this._router.navigate(['/self-onboarding-signup']);
  }
}