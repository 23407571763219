import { Component, OnInit, EventEmitter, Output } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { Location } from '@angular/common';
import {
  GeneralEventService,
  ManageBreadcrumbService,
  RouteService,
  TokenStorage
} from 'foo-framework';
import { ActivatedRoute, Router } from '@angular/router';
import { get } from 'lodash';

@Component({
  selector: 'app-home',
  templateUrl: './pages.component.html',
  styleUrls: ['./pages.component.scss']
})
export class PagesComponent implements OnInit {
  isClosed = false;
  curLang: string;
  subscribegEvent: any;
  @Output() LangChangedEvent = new EventEmitter<string>();
  @Output() BackgroundImageDisplay = new EventEmitter<boolean>();
  showLang = false;
  showLeftMenu = true;
  showLogo = false;
  defaultState;
  menu = [];

  constructor(
    private translate: TranslateService,
    private gEvent: GeneralEventService,
    private location: Location,
    private routeService: RouteService,
    public manageBreadcrumb: ManageBreadcrumbService,
    private router: Router,
    private tokenStorage: TokenStorage,
    private route: ActivatedRoute
  ) {

    if (localStorage.getItem('curLang')) {
      this.curLang = localStorage.getItem('curLang');
      translate.setDefaultLang(localStorage.getItem('curLang'));
    } else {
      localStorage.setItem('curLang', 'en');
      translate.setDefaultLang('en');
      this.curLang = 'en';
    }
  }

  ngOnInit(): void {
    this.showLang = JSON.parse(window['showlang']);
    this.showLeftMenu = JSON.parse(window['showleftmenu']);
    this.menu = JSON.parse(window['menu']);
    this.showLogo = JSON.parse(window['showlogo']);
    this.defaultState = this.tokenStorage.getConfiguration();
    this.tokenStorage.getConfiguration().subscribe(res => {
      this.defaultState = get(res, 'default_state') || '/home';
    });
  }

  toggleMenu($event) {
    // this.isClosed = !this.isClosed;
    // this.isClosed = this.routeService.menuClosed;
    //check if event false remove class menuclosed from page-content-wrapper class in pages.component.html
    if ($event) {
      this.isClosed = this.routeService.menuClosed;
    } else {
      this.isClosed = $event;
    }
  }

  useLanguage(language: string) {
    this.LangChangedEvent.emit(language);
    this.translate.use(language);
    localStorage.setItem('curLang', language);
    this.curLang = language;
  }

   goBack(event) {
    event?.preventDefault();
    event?.stopPropagation();
    this.routeService.beforeBackCall().subscribe(value => {
      if (value) {
        if (this.routeService.routesHistory.length > 1) {
          this.router.navigate([`/${this.routeService.routesHistory.slice(0, this.routeService.routesHistory.length - 1).join('/')}`]
            , {
              queryParams: {
                '--keep': true
              }
            });
        } else {
          if (this.defaultState !== null || this.defaultState !== undefined) {
            this.router.navigate([this.defaultState]);
          } else {
            this.router.navigate(['/home']);
          }
        }
      }
    });
  }
}
