import {LazyLoadedComponent} from 'foo-framework';
import {SharedWidgetsModule} from "foo-framework/shared-widgets";
import { ExternalPortalRouteHandlerService } from './routes-handlers/external-portal-route-handler.service';
import { ExternalLinkRouteHandlerService } from './routes-handlers/external-link-route-handler.service';

export class RoutesMapper {

  static modules = {
    ApiTokenModule: {
      load: () => import('foo-framework/modules/api-token').then(m => ({
        module: m.ApiTokenModule,
        components: m
      })),

      name: 'ApiTokenModule'
    },
    WebViewsModule: {
      load: () => import('foo-framework/modules/web-views').then(m => ({
        module: m.WebViewsModule,
        components: m
      })),
      name: 'WebViewsModule'
    },
    ComingSoonModule: {
      load: () => import('foo-framework/modules/coming-soon').then(m => ({
        module: m.ComingSoonModule,
        components: m
      })),
      name: 'ComingSoonModule'
    },
    SelfOnboardingModule: {
      load: () => import('foo-framework/modules/self-onboarding').then(m => ({
        module: m.SelfOnboardingModule,
        components: m
      })),
      name: 'SelfOnboardingModule'
    },
    SuperAdminIdvConfigurationModule: {
      load: () => import('foo-framework/modules/super-admin-idv-configuration').then(m => ({
        module: m.SuperAdminIdvConfigurationModule,
        components: m
      })),
      name: 'SuperAdminIdvConfigurationModule'
    },
    DefaultConfigurationModule: {
      load: () => import('foo-framework/modules/default-configuration').then(m => ({
        module: m.DefaultConfigurationModule,
        components: m
      })),
      name: 'DefaultConfigurationModule'
    },
    PushTrackingModule: {
      load: () => import('foo-framework/modules/push-tracking').then(m => ({
        module: m.PushTrackingModule,
        components: m
      })),
      name: 'PushTrackingModule'
    },
    ApplicationModule: {
      load: () => import('foo-framework/modules/application').then(m => ({
        module: m.ApplicationModule,
        components: m
      })),
      name: 'ApplicationModule'
    },
    LocationsModule: {
      load: () => import('foo-framework/modules/locations').then(m => ({
        module: m.LocationsModule,
        components: m
      })),
      name: 'LocationsModule'
    },
    CardsCatalogueModule: {
      load: () => import('foo-framework/modules/cards-catalogue').then(m => ({
        module: m.CardsCatalogueModule,
        components: m
      })),
      name: 'CardsCatalogueModule'
    },
    CustomerModule: {
      load: () => import('foo-framework/modules/customer').then(m => ({
        module: m.CustomerModule,
        components: m
      })),
      name: 'CustomerModule'
    },
    VirtualCardsModule: {
      load: () => import('foo-framework/modules/virtual-cards').then(m => ({
        module: m.VirtualCardsModule,
        components: m
      })),
      name: 'VirtualCardsModule'
    },
    CardsOnFile: {
      load: () => import('foo-framework/modules/cards-on-file').then(m => ({
        module: m.CardsOnFile,
        components: m
      })),
      name: 'CardsOnFile'
    },
    CardsModule: {
      load: () => import('foo-framework/modules/cards').then(m => ({
        module: m.CardsModule,
        components: m
      })),
      name: 'CardsModule'
    },
    SvaModule: {
      load: () => import('foo-framework/modules/sva').then(m => ({
        module: m.SvaModule,
        components: m
      })),
      name: 'SvaModule'
    },
    IdvConfigurationModule: {
      load: () => import('foo-framework/modules/idv-configuration').then(m => ({
        module: m.IdvConfigurationModule,
        components: m
      })),
      name: 'IdvConfigurationModule'
    },
    IdvUsersModule: {
      load: () => import('foo-framework/modules/idv-users').then(m => ({
        module: m.IdvUsersModule,
        components: m
      })),
      name: 'IdvUsersModule'
    },
    ApprovalModule: {
      load: () => import('foo-framework/modules/approval').then(m => ({
        module: m.ApprovalModule,
        components: m
      })),
      name: 'ApprovalModule'
    },
    UsersModule: {
      load: () => import('foo-framework/modules/users').then(m => ({
        module: m.UsersModule,
        components: m
      })),
      name: 'UsersModule'
    },
    UserAnalyticsModule: {
      load: () => import('foo-framework/modules/user-analytics').then(m => ({
        module: m.UserAnalyticsModule,
        components: m
      })),
      name: 'UserAnalyticsModule'
    },
    AppVersionModule: {
      load: () => import('foo-framework/modules/app-version').then(m => ({
        module: m.AppVersionModule,
        components: m
      })),
      name: 'AppVersionModule'
    },
    DashboardModule: {
      load: () => import('foo-framework/modules/dashboard').then(m => ({
        module: m.DashboardModule,
        components: m
      })),
      name: 'DashboardModule'
    },
    SvaConfigurationModule: {
      load: () => import('foo-framework/modules/sva-configuration').then(m => ({
        module: m.SvaConfigurationModule,
        components: m
      })),
      name: 'SvaConfigurationModule'
    },
    ManageTemplateModule: {
      load: () => import('foo-framework/modules/manage-template').then(m => ({
        module: m.ManageTemplateModule,
        components: m
      })),
      name: 'ManageTemplateModule'
    },
    SecurityModule: {
      load: () => import('foo-framework/modules/security').then(m => ({
        module: m.SecurityModule,
        components: m
      })),
      name: 'SecurityModule'
    },
    FeedbackModule: {
      load: () => import('foo-framework/modules/feedback').then(m => ({
        module: m.FeedbackModule,
        components: m
      })),
      name: 'FeedbackModule'
    },
    FaqCategoryModule: {
      load: () => import('foo-framework/modules/faq-category').then(m => ({
        module: m.FaqCategoryModule,
        components: m
      })),
      name: 'FaqCategoryModule'
    },
    LinksModule: {
      load: () => import('foo-framework/modules/links').then(m => ({
        module: m.LinksModule,
        components: m
      })),
      name: 'LinksModule'
    },
    ProfileModule: {
      load: () => import('foo-framework/modules/profile').then(m => ({
        module: m.ProfileModule,
        components: m
      })),
      name: 'ProfileModule'
    },
    ReportModule: {
      load: () => import('foo-framework/modules/report').then(m => ({
        module: m.ReportModule,
        components: m
      })),
      name: 'ReportModule'
    },
    VerifyNumberModule: {
      load: () => import('foo-framework/modules/verify-number').then(m => ({
        module: m.VerifyNumberModule,
        components: m
      })),
      name: 'VerifyNumberModule'
    },
    VerifyEmailModule: {
      load: () => import('foo-framework/modules/verify-email').then(m => ({
        module: m.VerifyEmailModule,
        components: m
      })),
      name: 'VerifyEmailModule'
    },
    UsernameModule: {
      load: () => import('foo-framework/modules/username').then(m => ({
        module: m.UsernameModule,
        components: m
      })),
      name: 'UsernameModule'
    },
    EmailModule: {
      load: () => import('foo-framework/modules/email').then(m => ({
        module: m.EmailModule,
        components: m
      })),
      name: 'EmailModule'
    },
    MobileModule: {
      load: () => import('foo-framework/modules/mobile').then(m => ({
        module: m.MobileModule,
        components: m
      })),
      name: 'MobileModule'
    },
    PasswordModule: {
      load: () => import('foo-framework/modules/password').then(m => ({
        module: m.PasswordModule,
        components: m
      })),
      name: 'PasswordModule'
    },
    SendMessageModule: {
      load: () => import('foo-framework/modules/send-message').then(m => ({
        module: m.SendMessageModule,
        components: m
      })),
      name: 'SendMessageModule'
    },
    SharedModulesModule: {
      load: () => import('foo-framework/shared-modules').then(m => ({
        module: m.SharedModulesModule,
        components: m
      })),
      name: 'SharedModulesModule'
    },
    FormsModule: {
      load: () => import('foo-framework/modules/forms').then(m => ({
        module: m.FormsModule,
        components: m
      })),
      name: 'FormsModule'
    },
    AppModeModule: {
      load: () => import('foo-framework/modules/app-mode').then(m => ({
        module: m.AppModeModule,
        components: m
      })),
      name: 'AppModeModule'
    },
    MakerCheckerConfigModule: {
      load: () => import('foo-framework/modules/maker-checker-config').then(m => ({
        module: m.MakerCheckerConfigModule,
        components: m
      })),
      name: 'MakerCheckerConfigModule'
    },
    ApplicationsWorkflowModule: {
      load: () => import('foo-framework/modules/applications-workflow').then(m => ({
        module: m.ApplicationsWorkflowModule,
        components: m
      })),
      name: 'ApplicationsWorkflowModule'
    },
    ApplicationsModule: {
      load: () => import('foo-framework/modules/applications').then(m => ({
        module: m.ApplicationsModule,
        components: m
      })),
      name: 'ApplicationsModule'
    },
    SharedWidgetsModule: {
      load: () => import('foo-framework/shared-widgets').then(m => ({
        module: m.SharedWidgetsModule,
        components: m
      })),
      name: 'SharedWidgetsModule'
    },
    ReportsSetupModule: {
      load: () => import('foo-framework/modules/reports-setup').then(m => ({
        module: m.ReportsSetupModule,
        components: m
      })),
      name: 'ReportsSetupModule'
    },
    MenuExtensionsModule: {
      load: () => import('foo-framework/modules/menu-extensions').then(m => ({
        module: m.MenuExtensionsModule,
        components: m
      })),
      name: 'MenuExtensionsModule'
    },
    AtmsModule: {
      load: () => import('foo-framework/modules/atms').then(m => ({
        module: m.AtmsModule,
        components: m
      })),
      name: 'AtmsModule'
    },
    DynamicThemeModule: {
      load: () => import('foo-framework/modules/dynamic-theme').then(m => ({
        module: m.DynamicThemeModule,
        components: m
      })),
      name: 'DynamicThemeModule'
    },
    OnboardingRequestsModule: {
      load: () => import('foo-framework/modules/onboarding-requests').then(m => ({
        module: m.OnboardingRequestsModule,
        components: m
      })),
      name: 'OnboardingRequestsModule'
    },
    DynamicApprovalModule: {
      load: () => import('foo-framework/modules/dynamic-analytics-approval/dynamic-approval').then(m => ({
        module: m.DynamicApprovalModule,
        components: m
      })),
      name: 'DynamicApprovalModule'
    },
    DynamicAnalyticsModule: {
      load: () => import('foo-framework/modules/dynamic-analytics-approval/dynamic-analytics').then(m => ({
        module: m.DynamicAnalyticsModule,
        components: m
      })),
      name: 'DynamicAnalyticsModule'
    },
    AppConfigurationsModule: {
      load: () => import('foo-framework/modules/app-configurations').then(m => ({
        module: m.AppConfigurationsModule,
        components: m
      })),
      name: 'AppConfigurationsModule'
    },
    AgentModule: {
      load: () => import('foo-framework/modules/agent').then(m => ({
        module: m.AgentModule,
        components: m
      })),
      name: 'AgentModule'
    }
  };

  static preDefinedRouteConfigs = [
    {
      path: 'verify-number',
      component: LazyLoadedComponent,
      data: {
        title: 'Verification Page',
        canonical: '/verify-number',
        skylineBackground: true,
        breadcrumb: {label: 'menu.otp'},
        routeInfo: {
          module: RoutesMapper.modules.VerifyNumberModule,
          componentName: 'VerifyNumberComponent',
        }
      },
    },
    {
      path: 'verify-email',
      component: LazyLoadedComponent,
      data: {
        title: 'Verification Page',
        canonical: '/verify-email',
        skylineBackground: true,
        breadcrumb: {label: 'menu.verificationpage'},
        routeInfo: {
          module: RoutesMapper.modules.VerifyEmailModule,
          componentName: 'VerifyEmailComponent',
        }
      },
    },
    {
      path: 'manage-card',
      component: LazyLoadedComponent,
      parentComponents: [        {
        module: RoutesMapper.modules.CustomerModule,
        componentName: 'CustomerDetailsComponent'
      },
      {
        module: RoutesMapper.modules.CustomerModule,
        componentName: 'CustomerCardsComponent'
      }],
      data: {
        title: 'manage-card',
        canonical: '/manage-card',
        breadcrumb: {
          label: 'menu.manage-card',
          alias: 'manage-card',
          skip: true
        },
        routeInfo: {
          module: RoutesMapper.modules.CardsModule,
          componentName: 'ManageCardComponent',
        }
      },
    }
  ];

  static availableComponents = [
    {
      name: 'ApiTokenComponent',
      module: RoutesMapper.modules.ApiTokenModule,
      componentName: 'ApiTokenComponent'
    },
    {
      name: 'AddApiTokenComponent',
      module: RoutesMapper.modules.ApiTokenModule,
      componentName: 'AddApiTokenComponent'
    },
    {
      name: 'ComingSoonComponent',
      module: RoutesMapper.modules.ComingSoonModule,
      componentName: 'ComingSoonComponent'
    },
    {
      name: 'WebViewsComponent',
      module: RoutesMapper.modules.WebViewsModule,
      componentName: 'WebViewsComponent'
    },
    {
      name: 'AddWebViewComponent',
      module: RoutesMapper.modules.WebViewsModule,
      componentName: 'AddWebViewComponent'
    },
    {
      name: "CustomerInfoComponent",
      module: RoutesMapper.modules.CustomerModule,
      parentComponents: [{
        module: RoutesMapper.modules.CustomerModule,
        componentName: 'CustomerDetailsComponent'
      }],
      componentName: 'CustomerInfoComponent'
    },
    {
      name: "DevicesListComponent",
      module: RoutesMapper.modules.CustomerModule,
      parentComponents: [{
        module: RoutesMapper.modules.CustomerModule,
        componentName: 'CustomerDetailsComponent'
      },
        {
          module: RoutesMapper.modules.CustomerModule,
          componentName: 'CustomerDevicesComponent'
        }],
      componentName: 'DevicesListComponent'
    },
    {
      name: "CustomerSvaComponent",
      module: RoutesMapper.modules.CustomerModule,
      parentComponents: [{
        module: RoutesMapper.modules.CustomerModule,
        componentName: 'CustomerDetailsComponent'
      }],
      componentName: 'CustomerSvaComponent'
    },
    {
      name: "CustomerMicrofinanceComponent",
      module: RoutesMapper.modules.CustomerModule,
      parentComponents: [{
        module: RoutesMapper.modules.CustomerModule,
        componentName: 'CustomerDetailsComponent'
      }],
      componentName: 'CustomerMicrofinanceComponent'
    },
    {
      name: "MicrofinanceDetailsComponent",
      module: RoutesMapper.modules.CustomerModule,
      parentComponents: [{
        module: RoutesMapper.modules.CustomerModule,
        componentName: 'CustomerDetailsComponent'
      }],
      componentName: 'MicrofinanceDetailsComponent'
    },
    {
      name: "IdvUsersDetailsComponent",
      module: RoutesMapper.modules.IdvUsersModule,
      componentName: 'IdvUsersDetailsComponent'
    },
    {
      name: "IdvLogsDetailsComponent",
      module: RoutesMapper.modules.IdvUsersModule,
      componentName: 'IdvLogsDetailsComponent'
    },
    {
      name: "CustomerCardsListComponent",
      module: RoutesMapper.modules.CustomerModule,
      parentComponents: [{
        module: RoutesMapper.modules.CustomerModule,
        componentName: 'CustomerDetailsComponent'
      }],
      componentName: 'ListComponent'
    },
    {
      name: 'CustomerCardTransactionsHistoryComponent',
      parentComponents: [
        {
          module: RoutesMapper.modules.CustomerModule,
          componentName: 'CustomerDetailsComponent'
        },
        {
          module: RoutesMapper.modules.CustomerModule,
          componentName: 'CustomerCardsComponent'
        }],
      module: RoutesMapper.modules.CardsModule,
      componentName: 'CardTransactionsComponent'
    },

    {
      name: 'SvaTransactionTypeComponent',
      module: RoutesMapper.modules.SvaConfigurationModule,
      componentName: 'SvaTransactionTypeComponent'
    },
    {
      name: 'ComingSoonComponent',
      module: RoutesMapper.modules.ComingSoonModule,
      componentName: 'ComingSoonComponent'
    },
    {
      name: 'SuperAdminIdvConfiguration',
      module: RoutesMapper.modules.SuperAdminIdvConfigurationModule,
      componentName: 'SuperAdminIdvConfigurationComponent'
    },
    {
      name: 'SuperAdminIdvConfigurationDetails',
      module: RoutesMapper.modules.SuperAdminIdvConfigurationModule,
      componentName: 'SuperAdminIdvCountryDetailsComponent'
    },
    {
      name: 'DefaultConfigurationComponent',
      module: RoutesMapper.modules.DefaultConfigurationModule,
      componentName: 'DefaultConfigurationComponent'
    },
    {
      name: 'DefaultConfigurationDetailsComponent',
      module: RoutesMapper.modules.DefaultConfigurationModule,
      componentName: 'DefaultConfigurationCountryDetailsComponent'
    },
    {
      name: 'PushTrackingComponent',
      module: RoutesMapper.modules.PushTrackingModule,
      componentName: 'PushTrackingListComponent'
    },
    {
      name: 'PushTrackingDetailsComponent',
      module: RoutesMapper.modules.PushTrackingModule,
      componentName: 'PushTrackingDetailsComponent'
    },
    {
      name: 'AllApplicationsComponent',
      module: RoutesMapper.modules.ApplicationModule,
      componentName: 'ApplicationComponent'
    },
    {
      name: 'AllApplicationsDetailsComponent',
      module: RoutesMapper.modules.ApplicationModule,
      componentName: 'ApplicationDetailsComponent'
    },
    {
      name: 'LocationComponent',
      module: RoutesMapper.modules.LocationsModule,
      componentName: 'LocationsComponent'
    },
    {
      name: 'CardsCatalogueListComponent',
      module: RoutesMapper.modules.CardsCatalogueModule,
      componentName: 'CardsCatalogueListComponent'
    },
    {
      name: 'CardsCatalogueDetailsComponent',
      module: RoutesMapper.modules.CardsCatalogueModule,
      componentName: 'CardsCatalogueDetailsComponent'
    },
    {
      name: 'EmployeeComponent',
      module: RoutesMapper.modules.CustomerModule,
      componentName: 'CustomerComponent'
    },
    {
      name: 'CustomerComponent',
      module: RoutesMapper.modules.CustomerModule,
      componentName: 'CustomerComponent'
    },
    {
      name: 'CustomerDetailsComponent',
      module: RoutesMapper.modules.CustomerModule,
      componentName: 'CustomerDetailsComponent'
    },
    {
      name: 'VirtualCardsComponent',
      module: RoutesMapper.modules.VirtualCardsModule,
      componentName: 'VirtualCardsComponent'
    },
    {
      name: 'CustomerCardsComponent',
      module: RoutesMapper.modules.VirtualCardsModule,
      componentName: 'CustomerCardsComponent'
    },
    {
      name: 'CustomerCardsComponent',
      module: RoutesMapper.modules.CardsOnFile,
      componentName: 'CardsOnFileComponent'
    },
    {
      name: 'CustomerCardsComponent',
      module: RoutesMapper.modules.CardsOnFile,
      componentName: 'CustomerCardsComponent'
    },
    {
      name: 'SvaAccountsComponent',
      module: RoutesMapper.modules.SvaModule,
      componentName: 'SvaComponent'
    },
    {
      name: 'SvaAccountDetailsComponent',
      module: RoutesMapper.modules.SvaModule,
      componentName: 'SvaAccountDetailsComponent'
    },
    {
      name: 'SvaTransactionDetailsComponent',
      module: RoutesMapper.modules.SvaModule,
      componentName: 'SvaTransactionDetailsComponent'
    },
    {
      name: 'IdvConfigurationComponent',
      module: RoutesMapper.modules.IdvConfigurationModule,
      componentName: 'IdvConfigurationComponent'
    },
    {
      name: 'IdvConfigurationDetailsComponent',
      module: RoutesMapper.modules.IdvConfigurationModule,
      componentName: 'IdvCountryDetailsComponent'
    },
    {
      name: 'IdVerificationUsersComponent',
      module: RoutesMapper.modules.IdvUsersModule,
      componentName: 'IdVerificationUsersComponent'
    },
    {
      name: 'IdvLogsComponent',
      module: RoutesMapper.modules.IdvUsersModule,
      componentName: 'IdvLogsComponent'
    },
    {
      name: 'SvaConfigurationComponent',
      module: RoutesMapper.modules.SvaConfigurationModule,
      componentName: 'SvaConfigurationComponent'
    },


    {
      name: 'AppModeComponent',
      module: RoutesMapper.modules.AppModeModule,
      componentName: 'AppModeComponent'
    },
    {
      name: 'UserComponent',
      module: RoutesMapper.modules.UsersModule,
      componentName: 'UsersComponent'
    },
    {
      name: 'AddUserComponent',
      module: RoutesMapper.modules.UsersModule,
      componentName: 'AddUserComponent'
    },
    {
      name: 'AddRoleComponent',
      module: RoutesMapper.modules.UsersModule,
      componentName: 'AddRoleComponent'
    },
    {
      name: 'ApprovalComponent',
      module: RoutesMapper.modules.ApprovalModule,
      componentName: 'ApprovalComponent'
    },
    {
      name: 'TransactionsComponent',
      module: RoutesMapper.modules.ApprovalModule,
      componentName: 'ApproveRejectComponent'
    },
    ...((!window['api_version'] || window['api_version'] === 'v1') ? [
      {
        name: 'UserAnalyticsComponent',
        module: RoutesMapper.modules.UserAnalyticsModule,
        componentName: 'UserAnalyticsComponent'
      },
      {
        name: 'ApproveRejectAsListComponent',
        module: RoutesMapper.modules.ApprovalModule,
        componentName: 'ApproveRejectAsListComponent'
      }
     ] : [
      {
        name: 'UserAnalyticsComponent',
        module: RoutesMapper.modules.DynamicAnalyticsModule,
        componentName: 'DynamicUserAnalyticsComponent'
      },
      {
        name: 'ApproveRejectAsListComponent',
        module: RoutesMapper.modules.DynamicApprovalModule,
        componentName: 'DynamicApprovalComponent'
      }
    ]),
    {
      name: 'SecurityComponent',
      module: RoutesMapper.modules.SecurityModule,
      componentName: 'SecurityComponent'
    },
    {
      name: 'AppVersionComponent',
      module: RoutesMapper.modules.AppVersionModule,
      componentName: 'AppVersionComponent'
    },
    {
      name: 'EmailComponent',
      module: RoutesMapper.modules.EmailModule,
      componentName: 'EmailComponent'
    },
    {
      name: 'MobileComponent',
      module: RoutesMapper.modules.MobileModule,
      componentName: 'MobileComponent'
    },
    {
      name: 'PasswordComponent',
      module: RoutesMapper.modules.PasswordModule,
      componentName: 'PasswordComponent'
    },
    {
      name: 'UsernameComponent',
      module: RoutesMapper.modules.UsernameModule,
      componentName: 'UsernameComponent'
    },
    {
      name: 'UserProfileComponent',
      module: RoutesMapper.modules.ProfileModule,
      componentName: 'ProfileComponent'
    },
    {
      name: 'DashboardComponent',
      module: RoutesMapper.modules.DashboardModule,
      componentName: 'CmsDashboardComponent'
    },
    {
      name: 'FeedbackComponent',
      module: RoutesMapper.modules.FeedbackModule,
      componentName: 'FeedbackComponent'
    },
    {
      name: 'PageComponent',
      module: RoutesMapper.modules.LinksModule,
      componentName: 'LinksComponent'
    },
    {
      name: 'StarRatingComponent',
      module: RoutesMapper.modules.SharedModulesModule,
      componentName: 'StarRatingComponent'
    },
    {
      name: 'FeedbackDetailsComponent',
      module: RoutesMapper.modules.FeedbackModule,
      componentName: 'FeedbackDetailsComponent'
    },
    {
      name: 'AddReplyComponent',
      module: RoutesMapper.modules.FeedbackModule,
      componentName: 'AddReplyComponent'
    },
    {
      name: 'FaqCategoryComponent',
      module: RoutesMapper.modules.FaqCategoryModule,
      componentName: 'FaqCategoryComponent'
    },
    {
      name: 'FaqCategoryDetailsComponent',
      module: RoutesMapper.modules.FaqCategoryModule,
      componentName: 'FaqCategoryDetailsComponent'
    },
    {
      name: 'AddFaqCategoryComponent',
      module: RoutesMapper.modules.FaqCategoryModule,
      componentName: 'AddFaqCategoryComponent'
    },
    {
      name: 'AddFaqCategoryDetailsComponent',
      module: RoutesMapper.modules.FaqCategoryModule,
      componentName: 'AddFaqCategoryDetailsComponent'
    },
    {
      name: 'ReportComponent',
      module: RoutesMapper.modules.ReportModule,
      componentName: 'ReportComponent'
    },
    {
      name: 'ManageTemplateComponent',
      module: RoutesMapper.modules.ManageTemplateModule,
      componentName: 'ManageTemplateComponent'
    },
    {
      name: 'SendMessageComponent',
      module: RoutesMapper.modules.SendMessageModule,
      componentName: 'SendMessageComponent'
    },
    {
      name: 'FormsComponent',
      module: RoutesMapper.modules.FormsModule,
      componentName: 'FormsComponent'
    },
    {
      name: 'FormDetailsComponent',
      module: RoutesMapper.modules.FormsModule,
      componentName: 'FormDetailsComponent'
    },
    {
      name: 'MakerCheckerConfigComponent',
      module: RoutesMapper.modules.MakerCheckerConfigModule,
      componentName: 'MakerCheckerConfigComponent'
    },
    {
      name: 'ApplicationsWorkflowComponent',
      module: RoutesMapper.modules.ApplicationsWorkflowModule,
      componentName: 'ApplicationsWorkflowComponent'
    },
    {
      name: 'WorkflowDetailsComponent',
      module: RoutesMapper.modules.ApplicationsWorkflowModule,
      componentName: 'WorkflowDetailsComponent'
    },
    {
      name: 'GroupsListComponent',
      module: RoutesMapper.modules.ApplicationsModule,
      componentName: 'GroupsListComponent'
    },
    {
      name: 'ApplicationsListComponent',
      module: RoutesMapper.modules.ApplicationsModule,
      componentName: 'ApplicationsListComponent'
    },
    {
      name: 'ApplicationDetailsComponent',
      module: RoutesMapper.modules.ApplicationsModule,
      componentName: 'ApplicationDetailsComponent'
    },
    {
      name: 'ReportsListComponent',
      module: RoutesMapper.modules.ReportsSetupModule,
      componentName: 'ReportsListComponent'
    },
    {
      name: 'ReportAddComponent',
      module: RoutesMapper.modules.ReportsSetupModule,
      componentName: 'ReportAddComponent'
    },
    {
      name: 'OldRemittanceService',
      service: ExternalPortalRouteHandlerService
    },
    {
      name: 'MenuExtensionsComponent',
      module: RoutesMapper.modules.MenuExtensionsModule,
      componentName: 'MenuExtensionsComponent'
    },
    {
      name: 'MenuExtensionComponent',
      module: RoutesMapper.modules.MenuExtensionsModule,
      componentName: 'MenuExtensionComponent'
    },
    {
      name: 'open_in_new_tab',
      service: ExternalLinkRouteHandlerService
    },
    {
      name: 'AddMenuExtensionComponent',
      module: RoutesMapper.modules.MenuExtensionsModule,
      componentName: 'AddMenuExtensionComponent'
    },
    {
      name: 'AtmsComponent',
      module: RoutesMapper.modules.AtmsModule,
      componentName: 'AtmsComponent'
    },
    {
      name: 'DynamicThemesComponent',
      module: RoutesMapper.modules.DynamicThemeModule,
      componentName: 'DynamicThemeComponent'
    },
    {
      name: 'OnboardingRequestsComponent',
      module: RoutesMapper.modules.OnboardingRequestsModule,
      componentName: 'OnboardingRequestsListComponent'
    },
    {
      name: 'OnboardingRequestsDetailsComponent',
      module: RoutesMapper.modules.OnboardingRequestsModule,
      componentName: 'OnboardingRequestDetailsComponent'
    },
    {
      name: 'AppConfigurationComponent',
      module: RoutesMapper.modules.AppConfigurationsModule,
      componentName: 'AppConfigurationsComponent'
    },
    {
      name: 'AgentComponent',
      module: RoutesMapper.modules.AgentModule,
      componentName: 'AgentComponent'
    },
    {
      name: 'AgentDetailsComponent',
      module: RoutesMapper.modules.AgentModule,
      componentName: 'AgentDetailsComponent'
    }
  ];

  constructor() {
  }
}
