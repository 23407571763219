<div *ngIf="showFirstLoad" class="firstLoadOuter {{params.firstLoadClasses ? params.firstLoadClasses : ''}}">
    <div *ngIf="!isAnimated" class="firstLoadInner">
        <img alt="{{params.imageAlt ? params.imageAlt : 'search criteria'}}" src="{{params.imageUrl ? params.imageUrl : '/assets/img/firstLoadSearchIcon.svg'}}"/>
        <div class="firstLoadText mt-4">
            <span *ngIf="params.subtext">{{"general.enteryoursearchcriteria" | translate}} {{ isObservable(params.subtext) ? (params.subtext | async) : params.subtext}}</span>
            <span *ngIf="params.text" class="{{params.textClass ? params.textClass: ''}}">{{ isObservable(params.text) ? (params.text | async) :  params.text}}</span>
            <span *ngIf="params.hint" class="{{params.hintClass ? params.hintClass: ''}}">{{ isObservable(params.hint) ? (params.hint | async) : params.hint}}</span>
        </div>
    </div>
  <div *ngIf="isAnimated" class="firstLoadInner">
    <lottie-player src="{{params.imageUrl ? params.imageUrl : '/assets/firstLoadSearchIcon.json'}}"  background="transparent"  speed="1" class="lottie-loader" autoplay></lottie-player>
    <div class="firstLoadText mt-4">
      <span *ngIf="params.subtext">{{"general.enteryoursearchcriteria" | translate}} {{ isObservable(params.subtext) ? (params.subtext | async) : params.subtext}}</span>
      <span *ngIf="params.text" class="{{params.textClass ? params.textClass: ''}}">{{ isObservable(params.text) ? (params.text | async) :  params.text}}</span>
      <span *ngIf="params.hint" class="{{params.hintClass ? params.hintClass: ''}}">{{ isObservable(params.hint) ? (params.hint | async) : params.hint}}</span>
    </div>
  </div>
</div>
