<div [class.shimmer-animate]="isShimmeringDetails[index]" [class.border-top]="boxView">
    <div class="row shimmer-row pt-16">
        <div
            class="col-lg-6 col-md-6 col-sm-12 col-xs-12 {{ !detailsObj.displayAsList ? 'd-flex flex-column pl-15' :'pl-24'}}">

            <ng-container *ngIf="detailsObj.displayAsList; else listOfEntriesAsLine">
                <ul class="groups-list pl-15 mb-0" [class.list-style-none]="isShimmeringDetails[index]">
                    <li *ngFor="let item of (!detailsObj?.getExpansionPanelData ? item[detailsObj.source] : expansionPanelData[index]); let last=last"
                        [class.pb-12]="!last">
                        <ng-container *ngFor="let value of detailsObj.values; let last = last;">
                            <ng-container>
                                <span class="shimmer-item shimmer-i-block word-break">{{ (item | stringobject: value)
                                    }}</span>
                                <span *ngIf="!last && !isShimmeringDetails[index]"> - </span>
                            </ng-container>
                        </ng-container>
                    </li>
                </ul>
            </ng-container>

            <ng-template #listOfEntriesAsLine>
                <ng-container
                    *ngFor="let detail of (!detailsObj?.getExpansionPanelData ? detailsObj?.values : expansionPanelData[index])">
                    <span class="shimmer-item shimmer-i-block word-break listOfEntriesAsLineColor"
                        [innerHTML]="(!detailsObj?.getExpansionPanelData ? (item | stringobject: detail) : ((detail?.key ? detail.key : '-') + ': ' + (detail?.value ? detail.value : '-'))) | link"></span>
                </ng-container>
            </ng-template>

        </div>
    </div>
</div>

<div *ngIf="detailsObj?.getExpansionPanelData && checkEmptyObj()">
    <framework-no-data></framework-no-data>
</div>