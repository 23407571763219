import { Component, inject } from '@angular/core';
import { AbstractControl, UntypedFormGroup } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { PermissionsService, RouteService, SearchFiltersService } from 'foo-framework';
import { asapScheduler } from 'rxjs';
import { DynamicListingLabel } from '../dynamic-listing/interfaces/dynamic-listing-label.interface';

export interface ISortingState {
  sort_direction: string,
  sort_field: string,
}

export interface ITabs {
  key: string;
  permissions?: ((key?: string ) => boolean) | string ; // if undefined don't check
  title: string | ((key?: string) => string);
  isDisabled?: boolean | ((key?: string) => boolean);
  className?: string;
  [key: string]: any;
}

@Component({
  template: '',
})
export abstract class BaseListingsComponent {
  protected permissionService = inject(PermissionsService);
  protected route = inject(ActivatedRoute);
  protected searchFiltersService = inject(SearchFiltersService);
  protected routeService = inject(RouteService);
  protected router = inject(Router);

  tabs: ITabs[] = [
    {
      key: 'default',
      permissions: '',
      title: 'Default'
    }
  ];

  activeTab = 'default';
  dataPerpage: number = 20;
  filterPanelState = 'closed';
  navigationClicked = false;

  paginationInput: {
    [key: string]: any
  } = {};
  sortingState: {
    [key: string]: any
  } = {};
  data: {
    [key: string]: any[]
  } = {};
  searchText: {
    [key: string]: string
  } = {};
  filter: {
    [key: string]: any
  } = {};
  form: {
    [key: string]: UntypedFormGroup
  } = {};
  pageNumber: {
    [key: string]: number
  } = {};
  boxesConfigArray: {
    [key: string]: any[]
  } = {};
  labels: {
    [key: string]: any[]
  } = {};
  totalData: {
    [key: string]: number
  } = {};
  btnList: {
    [key: string]: any
  } = {};

  abstract getData(pageNumber: number, tab: string): void
  abstract getLabels(tab: string): DynamicListingLabel[]
  abstract getBoxesFilter(tab: string): any[]
  abstract getInitSortingState(tab: string): ISortingState
  abstract getBtnList(tab: string): any[]
  abstract getForm(tab: string): UntypedFormGroup

  constructor() {
    asapScheduler.schedule(() => {
      this.callInitFunctions();
    })
  }

  callInitFunctions(changeTab = true) {
    this.setDefaultTabAfterCheckingPermissions();
    this.searchFiltersService.fillSearchInfo(this);
    this.initComponentTabs();
    if (this.activeTab && changeTab) {
      this.changeTab(this.activeTab);
    }
  }

  setDefaultTabAfterCheckingPermissions(): void {
    this.activeTab = null;
    const activeTab = this.route.snapshot.queryParams.active_tab;
    if (activeTab && this.checkIfTabHasPermission(activeTab)) {
      this.activeTab = activeTab;
      if (this.searchFiltersService.routeExists()) {
        this.form = this.searchFiltersService.getForm(this.form);
        this.searchText = this.searchFiltersService.getSearchText(this.searchText);
        this.pageNumber = this.searchFiltersService.getPageNumber(this.pageNumber);
        this.sortingState = this.searchFiltersService.getSortingState(this, this.sortingState, 'labels');
        this.filter[this.activeTab] = this.form[this.activeTab].value;
        console.log(this.form);

      }
    } else {
      for (let i = 0; i < this.tabs.length; i++) {
        if (this.checkIfTabHasPermission(this.tabs[i]?.key)) {
          this.activeTab = this.tabs[i]?.key;
          break;
        }
      }
    }
  }

  checkIfTabHasPermission(tab: string, action = 'can_read'): boolean {
    let tabObj: any = this.getTab(tab);
    if (!tabObj) {
      return false;
    }
    if(typeof tabObj?.permissions === 'function'){
      return tabObj.permissions(tab);
    }else {
      return tabObj?.permissions ? this.permissions?.[tabObj.permissions]?.[action] : this.permissions?.[action];
    }
  }


  initComponentTabs(): void {
    this.tabs.forEach(tab => {
      if (this.checkIfTabHasPermission(tab.key)) {
        this.initListingByTab(tab.key);
      }
    });
  }

  initListingByTab(tab: string) {
    this.data[tab] = [{}];
    this.pageNumber[tab] = this.pageNumber[tab] || 1;
    this.totalData[tab] = null;
    this.searchText[tab] = this.searchText[tab] || '';
    this.filter[tab] = this.form[tab] ? this.filterAdapter(this.form[tab].value) : {};
    this.form[tab] = this.form[tab] || this.getForm(tab) || null;
    this.btnList[tab] = this.btnList[tab] || this.getBtnList(tab) || [];
    this.labels[tab] = this.labels[tab] || this.getLabels(tab) || [];
    this.boxesConfigArray[tab] = this.boxesConfigArray[tab] || this.getBoxesFilter(tab) || [];
    this.sortingState[tab] = this.sortingState[tab] || this.getInitSortingState(tab) || null;
    this.setPaginator(tab);
  }

  filterAdapter(filter: any): any {
    return { ...(filter || {}) };
  }

  setPaginator(tab: string): void {
    this.paginationInput[tab] = {
      pageNumber: this.pageNumber[tab],
      totalData: this.totalData[tab],
      dataPerpage: this.dataPerpage
    };
  }

  get permissions(): any {
    return this.permissionService.permissions;
  }

  getTab(name: string) {
    return this.tabs.find(t => t.key === name);
  }

  f(tab: string): { [key: string]: AbstractControl; } {
    return this.form[tab]?.controls;
  }

  setData(tab: string, data, dataName: string = 'items') {
    this.data[tab] = data?.[dataName] || [];
    this.totalData[tab] = data['total'];
    this.setPaginator(tab);
    this.navigationClicked = false;
  }

  changeTab(tab: string): void {
    this.toggleFilter('closed');
    this.setPaginator(tab);
    this.activeTab = tab;
    this.getData(this.pageNumber[this.activeTab], this.activeTab);
    this.searchFiltersService.setSearchInfo(this);
    this.redirect();
  }

  redirect() {
    const queryActiveTab = this.route.snapshot.queryParams.active_tab;
    if (queryActiveTab) {
      const params = { ...this.route.snapshot.queryParams, active_tab: this.activeTab };
      this.routeService.persistParams = (this.routeService.persistParams || []).filter(i => !i.startsWith('active_tab='));
      this.routeService.persistParams.push(`active_tab=${this.activeTab}`)
      this.router.navigate([], { queryParams: params });
    }
  }
  toggleFilter(data): void {
    this.filterPanelState = data;
  }

  searchFilter(tab: string): void {
    this.navigationClicked = true;
    this.filterPanelState = 'closed';
    this.pageNumber[tab] = 1;
    this.filter[tab] = this.filterAdapter(this.form[tab].value);
    this.getData(1, tab);
  }

  clearSearch(tab: string): void {
    this.form[tab].reset();
    this.searchFilter(tab);
  }

  searchData(search, tab: string): void {
    this.navigationClicked = true;
    this.searchText[tab] = search;
    this.pageNumber[tab] = 1;
    this.getData(this.pageNumber[tab], tab);
  }

  clearSearchInput(data, tab: string): void {
    this.searchData('', tab);
  }

  getDataByOrder(label: any, tab: string): void {
    if (label?.sortingValue || label?.sort_field) {
      this.sortingState[tab] = {
        sort_direction: label?.sorting ? label.sorting : label?.sort_direction,
        sort_field: label?.sortingValue ? label.sortingValue : label?.sort_field,
      };
      this.getData(this.pageNumber[tab], tab);
    }
  }

  functionCalled($event): void {
    this[$event.functionName]($event.item);
  }
}
