import {
  AbstractControl,
} from '@angular/forms';
import { Injectable } from '@angular/core';
import { CustomValidatorsService } from 'foo-framework';

@Injectable({
  providedIn: 'root',
})
export class BenefitCustomValidatorsService extends CustomValidatorsService {
  public checkPattern(type: string) {
    let pattern: any = '';
    if (type === 'url') {
      pattern = /^(http|https)?:\/\/[a-zA-Z0-9-\.]+\.[a-z]{2,4}/;
      const regex = new RegExp(pattern);

      return (control: AbstractControl): { [key: string]: boolean } | null => {
        if (!regex.test(control.value) && control.dirty && control.value != '') {
          return { 'pattern': true };
        }
        return null;
      };
    }
    return super.checkPattern(type);
  }
}