import { Component, OnInit, Input, EventEmitter, Output } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { Location } from '@angular/common';

@Component({
  selector: 'app-unauth-home',
  templateUrl: './unauth-pages.component.html',
  styleUrls: ['./unauth-pages.component.scss']
})
export class UnauthPagesComponent implements OnInit {
  
  isClosed = false;
  curLang : string;
  @Output() LangChangedEvent = new EventEmitter<string>();
  constructor(private translate: TranslateService,private location: Location) {
    
    if(localStorage.getItem("curLang")){
      this.curLang = localStorage.getItem("curLang");
      translate.setDefaultLang(localStorage.getItem("curLang"));
    }else{
      localStorage.setItem("curLang", "en");
      translate.setDefaultLang('en');
      this.curLang = "en";
    }
}

  ngOnInit(): void {
  }

  changeLanguage(){
    var language = "en";
    if(localStorage.getItem("curLang") == "en"){
      language = "ar";
    }
    this.LangChangedEvent.emit(language);
    this.translate.use(language);
    localStorage.setItem('curLang', language);
    this.curLang = language;
  }
  useLanguage(language:string){
    this.LangChangedEvent.emit(language);
    this.translate.use(language);
    localStorage.setItem('curLang', language);
    this.curLang = language;
  }
  goBack(){
    this.location.back();
  }
}
