<div ngbDropdown #drop container="body" placement="{{placement}}"
     (mouseenter)="!itemShimmer && hoverOpen(drop)" (mouseleave)="hoverClose(drop)">
  <button type="button" class="circle-actions circle-actions-small pl-20 {{tooltipToggleClassName}}" ngbDropdownToggle [disabled]="itemShimmer">
    <foo-icon *ngIf="tooltipToggleIcon" [icon]="tooltipToggleIcon"></foo-icon>
    <span *ngIf="tooltipToggleText">{{tooltipToggleText}}</span>
  </button>
  <div ngbDropdownMenu class="more-options-dropdown" (mouseenter)="hoverOpen(drop)"
       (mouseleave)="hoverClose(drop)">
    <ng-container *ngFor="let item of items">
     <ng-container  *ngIf="!item?.hidden">
      <button *ngIf="item?.fctName" ngbDropdownItem
              class="m-0 d-flex align-items-center {{item?.className?item?.className:''}}"
              (click)="btnFunctionCalled(item?.fctName, item?.fctParams)"
              [disabled]="itemShimmer||item?.disabled?item.disabled:false"
      >
        {{ item?.title | translate}}

        <ng-container *ngIf="getTemplate(item?.templateRef)">
          <ng-container
            *ngTemplateOutlet="getTemplate(item?.templateRef); context: { item: item,templateParams:item.templateParams}">
          </ng-container>
        </ng-container>
      </button>
        <span *ngIf="!item?.fctName" class="dropdown-item dropdown-item-notclickable">{{ item?.title | translate}}</span>
    </ng-container>
   </ng-container>
  </div>

</div>
<ng-template [frameworkTemplate]="'statusItem'" let-item="item" let-templateParams="templateParams">
   <span *ngIf="templateParams"
         class="pl-2 {{templateParams?.classOptions ? ( templateParams.classOptions.hasOwnProperty(templateParams.value) ? templateParams.classOptions[templateParams.value] : '' ) : '' }}">
      <span
        class="font-weight-400 text-capitalize">{{(templateParams?.displayName || templateParams?.value)| translate }}</span>
   </span>
</ng-template>
<ng-template [frameworkTemplate]="'textIconItem'" let-item="item" let-templateParams="templateParams">
  <span *ngIf="templateParams"
        class="pl-2 {{templateParams?.className}}"> {{ templateParams?.value| translate }} </span>
  <span *ngIf="templateParams">
      <span class="{{templateParams?.iconClassName}}"></span>
   </span>
</ng-template>
