<div *ngIf="!autoCompleteSearchBar"class="search-bar-container search-container pl-0" [class.has-shimmer]="shimmer" [class.has-no-filter]="!hasFilter" [class.modal-style]="isModal"  [class.dropdown-style]="isDropdown">
  <div class="add-seach-icon {{ hasButton ? 'has-search-input-btn' : '' }} {{ searchIconBtn ? 'has-search-icon-btn' : 'has-search-text-btn'}} {{ filterIconBtn ? 'has-filter-icon-btn' : 'has-filter-text-btn'}}">
    <ng-container *ngIf="!hasButton">
      <div class="deleteicon">
        <input type="text" [(ngModel)]="searchText" #searchInput class="form-input search-icon {{searchInput.value.length ? 'focused' : ''}}"
               [disabled]="shimmer || disableSearchInput"
               [readOnly]="disableSearchInput"
               (keydown.enter)="this.hasButton && initSearch()"
               placeholder='{{ placeholder | translate }}'/>
        <button *ngIf="searchInput.value.length && !shimmer" [disabled]="shimmer" (click)="clearSearch()" class="close-icon"></button>
      </div>
    </ng-container>
    <ng-container *ngIf="hasButton">
      <div class="input-group">
        <div class="deleteicon" [ngClass]="{'w-100' : searchButton.classList.contains('disableButton')}">
          <ng-container *ngIf="autoCompleteSearchBar;else other_content">
            <input type="text" #searchInput placeholder='{{ placeholder | translate }}' class="form-control form-input" matInput
                   [formControl]="myControl" [matAutocomplete]="auto" [disabled]="shimmer">

            <mat-autocomplete #auto="matAutocomplete" (optionSelected)="selectedOption($event)" [displayWith]="getOptionText">
              <mat-option *ngFor="let option of filteredOptions | async" [value]="option">
                {{ option.value }}
              </mat-option>
            </mat-autocomplete>
            <button *ngIf="searchInput.value.length && !shimmer" [disabled]="shimmer" (click)="clearSearch()" class="close-icon"></button>
          </ng-container>
          <ng-template #other_content>
            <input type="text" [(ngModel)]="searchText" #searchInput
                   class="form-control form-input {{searchInput.value.length ? 'focused' : ''}}"
                   [disabled]="shimmer || disableSearchInput"
                   [readOnly]="disableSearchInput"
                   (keydown.enter)="this.hasButton && initSearch()"
                   placeholder='{{ placeholder | translate }}'/>
            <button *ngIf="searchInput.value.length && !shimmer" [disabled]="shimmer" (click)="clearSearch()" class="close-icon"></button>
          </ng-template>
        </div>
        <div class="input-group-append">
          <button #searchButton (click)="initSearch(autoCompleteData)" [disabled]="shimmer || disableSearchInput"
                  class="search-btn {{ !searchIconBtn ? 'foo-btn-primary' : 'foo-btn-outline-primary foo-btn-icon'}} {{ disableSearchInput ? 'disableButton' : '' }}">

            <span *ngIf="!searchIconBtn">{{ "general.search" | translate }} </span>
            <span *ngIf="searchIconBtn" class="foo-icon foo-search-primary"></span>
          </button>
        </div>
      </div>
    </ng-container>

    <button #advBtn1
      (click)="toggleFilter(advBtn1)"
      [disabled]="shimmer"
      class="{{ filterIconBtn ? 'foo-btn-outline-primary foo-btn-icon filter-icon-btn' : 'foo-btn-primary'}} {{ fiterPanelState === 'opened' ? 'filter-btn--selected' : ''}}"
      *ngIf="hasFilter"
    >
      <span *ngIf="filterIconBtn" class="foo-icon foo-filter-primary" ></span>
      <span *ngIf="!filterIconBtn">{{ "general.advancedSearch" | translate }} </span>
    </button>
  </div>
</div>
<button #advBtn2
      (click)="toggleFilter(advBtn2)"
      [disabled]="shimmer"
      class="responsive-button {{ filterIconBtn ? 'foo-btn-outline-primary foo-btn-icon filter-icon-btn' : 'foo-btn-primary'}} {{ fiterPanelState === 'opened' ? 'filter-btn--selected' : ''}}"
      *ngIf="autoCompleteSearchBar"
    >
      <span *ngIf="filterIconBtn" class="foo-icon foo-filter-primary" ></span>
      <span *ngIf="!filterIconBtn">{{ "general.advancedSearch" | translate }} </span>
</button>
