<div class="conscent-box">
  <foo-control-label  [templateOptions]="templateOptions" [control]="control"></foo-control-label>
  <div class="foo-checkbox-option">
    <mat-checkbox class="form-control-checkbox" [formControl]="control">
      {{templateOptions?.label}}
      <a *ngFor="let val of templateOptions.items"
         (click)="openConsent(templateOptions, val.value)"
         href="javascript:void(0)">{{ val.label }} </a>
    </mat-checkbox>
  </div>
<foo-control-errors *ngIf="!templateOptions?.readonly && !templateOptions?.hideErrors"  [control]="control" [errorMessages]="errors"></foo-control-errors>
