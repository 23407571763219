<div class="flex sm-flex-col justify-content-between h-auto mb-{{marginBottom}} {{className}}" [class.align-items-center]="!hasSectionDescription" >
  <ng-container *ngIf="(menuType === 'main'); else subMenuType">
    <div class="d-flex flex-column">
      <h1 class="section-title">{{sectionTitle}}</h1>
      <h3 class="section-description" *ngIf="hasSectionDescription && sectionDescription !== sectionTitle">{{sectionDescription}}</h3>
    </div>
  </ng-container>
  <ng-template #subMenuType>
    <span class="section-title f-16">{{sectionTitle}}</span>
  </ng-template>
  <page-buttons
    [hideBtnListOnDesktop]="hideBtnListOnDesktop"
    [hideBtnListOnMobile]="hideBtnListOnMobile"
    [isBtnListDisabled]="isBtnListDisabled"
    [btnList]="btnList"
    [dataShimmer]="dataShimmer"
    (onFunctionCalledEvent)="onFunctionCalledEvent?.emit($event)">
  </page-buttons>
</div>
