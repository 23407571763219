<button mat-button mat-dialog-close class="close-icon">
  <span class="foo-icon foo-close-dark"></span>
</button>
<div mat-dialog-title >
  <p class="delete-text">{{data.title}}</p>
</div>
<mat-dialog-content [innerText]="data.text" class="mt-10">
</mat-dialog-content>
<mat-dialog-actions>
  <button mat-button mat-dialog-close class="foo-btn-outline-secondary mr-20 ml-0">{{'general.cancel' | translate}}</button>
  <button mat-button [class]="data.buttonClass ? data.buttonClass : 'foo-btn-danger'" (click)="onSubmit()">{{data.button ? data.button : ('general.delete' | translate)}}</button>
</mat-dialog-actions>
