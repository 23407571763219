<button class="close-icon" mat-dialog-close><span class="foo-icon foo-close-dark"></span></button>
<div mat-dialog-title>
    <p>{{ dialogTitle }}</p>
</div>
<mat-dialog-content class="mt-10 pt-0" [class.shimmer-animate]="dataShimmer[activeTab]">

    <div class="row foo-tabs-menu mb-20" *ngIf="tabsConfigs.length > 1">
        <div class="col-12">
            <ul class="nav nav-pills auto-slider">
                <swiper [slidesPerView]="'auto'" [spaceBetween]="0" [navigation]="false">
                    <ng-template swiperSlide *ngFor="let tab of tabsConfigs; let i = index">
                        <li (click)="changeTab(tab.key)">
                            <a href="javascript:void(0)" [class.active]="activeTab == tab.key">
                                {{ tab.title | translate}}
                            </a>
                        </li>
                    </ng-template>
                </swiper>
            </ul>
        </div>
    </div>

    <ng-container *ngIf="activeTab">
        <search-bar *ngIf="tabs[activeTab]?.search" [shimmer]="dataShimmer[activeTab]" [hasFilter]="false"
            [hasButton]="false" [isModal]="true" [placeholder]="tabs[activeTab]?.search?.placeholder | translate"
            [class]="tabs[activeTab]?.search?.class" (onSearchEvent)="searchData($event, activeTab)"
            [navTab]="activeTab" [searchIconBtn]="false" [filterIconBtn]="false"
            (onClearEvent)="clearSearchInput($event, activeTab)"></search-bar>

        <!-- Tab panes -->
        <div class="tab-content clearfix pl-0 pt-24">
            <div class="tab-pane active">
                <div class="row">
                    <div class="col-12 col-sm-12 col-md-12 col-lg-12 p-0" *ngIf="items[activeTab]?.length">
                        <dynamic-listing (onGetDataEvent)="getData($event, activeTab)"
                            [paginationInput]="paginationInput[activeTab]" [dataInput]="items[activeTab]"
                            [labelsInput]="tabs[activeTab]?.labels" [dataShimmer]="dataShimmer[activeTab]"
                            [clickableRow]="false"
                            class="codes-list">
                        </dynamic-listing>
                    </div>
                </div>
            </div>
        </div>

    </ng-container>

</mat-dialog-content>
<div mat-dialog-actions class="pt-8">
    <button type="button" (click)="submit()" [class]="button?.class" [disabled]="dataShimmer[activeTab]">
        {{ button?.label }}
    </button>
</div>