<!-- BEGIN SIDEBAR -->
<app-header [showLang]="showLang" [logoSrc]="'/assets/img/alexbank_logo.svg'" (LangChangedEvent)="useLanguage($event)"
  [isFullWidth]="isClosed && showLeftMenu" [isFullMenu]="!showLeftMenu" [showLogo]="showLogo"></app-header>

<app-left-menu *ngIf="showLeftMenu" (onMenuClosedEvent)="toggleMenu($event)" (LangChangedEvent)="useLanguage($event)"
  [LangChanged]="curLang"></app-left-menu>
<!-- END SIDEBAR -->
<div class="page-content-wrapper" dir='{{"general.dir" | translate }}' [class.menuclosed]="isClosed"
  [class.menu-invisible]="!showLeftMenu">
  <div class="page-content">
    <xng-breadcrumb [class.portal-page-content]="!showLeftMenu" separator=">"
      *ngIf="manageBreadcrumb.isBreadcrumbShown">
      <ng-container *xngBreadcrumbItem="let breadcrumb;let first = first; let index = index; let count = count;">
        <ng-container *ngIf="first && count>1">
          <span class="hover-scale foo-icon foo-back-primary" (click)="goBack($event)"></span>
        </ng-container>
        <span *ngIf="count>1">{{
          (breadcrumb | TranslationFormat:'breadcrumb': curLang) || (breadcrumb | translate)
          }}</span>
      </ng-container>
    </xng-breadcrumb>
    <router-outlet (LangChangedEvent)="useLanguage($event)"></router-outlet>
  </div>
</div>