<div class="form-group">
  <foo-control-label [templateOptions]="templateOptions" [control]="control"></foo-control-label>
  <mat-form-field class="form-element input-tags-container mb-2" appearance="fill">
    <mat-chip-grid #chipList [formControl]="control">
      <mat-chip-row
        *ngFor="let tag of control.value"
        [removable]="true"
        (removed)="remove(tag)"
      >
        {{ tag }}
        <mat-icon matChipRemove>cancel</mat-icon>
      </mat-chip-row>
      <input
        #tagInput
        class="input-tags"
        [appNumbersOnly]="templateOptions?.onlyNumbers"
        [placeholder]="templateOptions?.placeholder | translate"
        [disabled]="disabled"
        [readonly]="disabled"
        [id]="id"
        (paste)="onPaste($event)"
        [matChipInputFor]="chipList"
        (matChipInputTokenEnd)="add($event)"
        [matChipInputAddOnBlur]="addOnBlur"
        [matChipInputSeparatorKeyCodes]="separatorKeyCodes"
      />
    </mat-chip-grid>
  </mat-form-field>
  <small class="foo-text-light w-100 d-block">{{'fooInputTags.toEnterMultiple' | translate : {label: templateOptions?.label} }}</small>
  <foo-control-errors *ngIf="!templateOptions?.readonly && !templateOptions?.hideErrors"  [control]="control" [errorMessages]="errors"></foo-control-errors>
</div>
