<div
  class="
    first-resp-input
    desktopPadRightLeft10
  "
>
  <input
    #myRangeInput
    class="form-control input-calendar"
    placeholder="mm/dd/yyyy - mm/dd/yyyy"
    name="dp"
    ngbDatepicker
    [dayTemplate]="dayTemplate"
    [readonly]="true"
    (click)="!disabled && d.toggle();"
    [autoClose]="false"
    [displayMonths]="2"
    [disabled]="disabled"
    [maxDate]="max"
    [minDate]="min"
    #d="ngbDatepicker"
  />
  <div class="input-calendar-append">
    <button
      class="btn btn-outline-secondary calendar"
      (click)="!disabled && d.toggle()"
      type="button"
    ></button>
  </div>
</div>
<ng-template #dayTemplate let-date="date" let-focused="focused">
  <span
    class="custom-day"
    [class.range]="
      isFrom(date) || isTo(date) || isInside(date) || isHovered(date)
    "
    [class.faded]="isHovered(date) || isInside(date)"
    (click)="onDateSelection(date)"
    (mouseenter)="hoveredDate = dateStructToDate(date)"
    (mouseleave)="hoveredDate = null"
  >
    {{ date.day }}
  </span>
</ng-template>
