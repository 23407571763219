<div *ngIf="isAnimated" class="no-data-container" [ngStyle]="centerInPage && {position: 'relative', top: marginTop + 'px'}" [class.no-data-container--margin]="!centerInPage">
    <div *ngIf="!isEmptySearch">
      <lottie-player *ngIf="!params.hideImage" class="lottie-loader no-data-image {{params.imageClassName ? params.imageClassName : ''}}" src="{{params.imageUrl ? params.imageUrl : '/assets/no-data-found.json'}}" speed="1" autoplay></lottie-player>
        <span *ngIf="!params.hideText" class="no-data-text {{params.textClassName ? params.textClassName : ''}}" [class.mt-4]="!params.hideImage">{{ params.text ? params.text : ("general.nodatafound" | translate)}}</span>
    </div>
    <div *ngIf="isEmptySearch">
      <lottie-player *ngIf="!params.hideSearchImage" class="lottie-loader no-data-image no-data-image--search {{params.searchImageClassName ? params.searchImageClassName : ''}}" src="{{params.searchImageUrl ? params.searchImageUrl : '/assets/firstLoadSearchIcon.json'}}"  background="transparent"  speed="1" autoplay></lottie-player>
        <span *ngIf="!params.hideSearchText" class="no-data-text {{params.searchTextClassName ? params.searchTextClassName : ''}}" [class.mt-4]="!params.hideSearchImage">{{ params.searchText ? params.searchText : ("general.nosearchdatafound" | translate)}}</span>
    </div>
</div>
<div *ngIf="!isAnimated" class="no-data-container" [ngStyle]="centerInPage && {position: 'relative', top: marginTop + 'px'}" [class.no-data-container--margin]="!centerInPage">
  <div *ngIf="!isEmptySearch">
    <img *ngIf="!params.hideImage" class="no-data-image {{params.imageClassName ? params.imageClassName : ''}}" src="{{params.imageUrl ? params.imageUrl : '/assets/img/no-data-found.svg'}}" alt="No data found">
    <span *ngIf="!params.hideText" class="no-data-text {{params.textClassName ? params.textClassName : ''}}" [class.mt-4]="!params.hideImage">{{ params.text ? params.text : ("general.nodatafound" | translate)}}</span>
  </div>
  <div *ngIf="isEmptySearch">
    <img *ngIf="!params.hideSearchImage" class="no-data-image no-data-image--search {{params.searchImageClassName ? params.searchImageClassName : ''}}" src="{{params.searchImageUrl ? params.searchImageUrl : '/assets/img/firstLoadSearchIcon.svg'}}" alt="No data found">
    <span *ngIf="!params.hideSearchText" class="no-data-text {{params.searchTextClassName ? params.searchTextClassName : ''}}" [class.mt-4]="!params.hideSearchImage">{{ params.searchText ? params.searchText : ("general.nosearchdatafound" | translate)}}</span>
  </div>
</div>

