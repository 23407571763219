<form [formGroup]="form">
  <h2 class="form-label" *ngIf="labelInput || labelNoTranslation">
    <div class="translationLabel" [class.d-inline-flex]="labelInputTooltip"><span class="lang" *ngIf="selector === 'editor'">{{ curLang }}</span> {{ labelNoTranslation ? labelInput : (labelInput | translate) }}<span
      *ngIf="fieldRequired&&isRequiredField(curLang,valueInput)">*</span>
      <ng-container *ngIf="labelInputTooltip">
        <div class="foo-icon foo-stack ml-1 foo-w-17 foo-h-17" #tooltip="matTooltip" matTooltipPosition="above"
             [matTooltip]="labelInputTooltip | translate">
          <span class="foo-icon foo-circle foo-shadow-primary w-100 h-100"></span>
          <span class="foo-icon foo-form-info-primary"></span>
        </div>
      </ng-container>
    </div>
    <small *ngIf="form.get('translations').get(curLang).get(valueInput).value && !hasOneLanguage">
      <a class="copyTranslation {{ readonly ? 'disableCopyTranslation' : '' }}" placement="{{curLang == 'en' ? 'right' : 'left'}}" [animation]="true"
        ngbTooltip="{{curLang == 'en' ? 'Copy value of ' + valueInput +' from '+ curLang + ' to other languages' : 'نسخ الحقل إلى لغات أخرى'}}"
        (click)="copyTranslation()">Copy</a></small>
  </h2>
  <div class="relative" formGroupName="translations">
    <div class="translationsCurrentLangContainer" formGroupName="{{curLang}}">
      <div [ngClass]="hasOneLanguage ? 'hasOneLanguage direct-parent' : 'direct-parent'">
        <span class="inputLanguage" [class.onelang]="languages.length == 1" *ngIf="selector !== 'editor' && !hasOneLanguage">{{curLang}}</span>
        <input *ngIf="selector == null || selector == 'input'" type="text" matInput
          [ngClass]="hasOneLanguage ? 'hasOneLanguage form-input' : 'form-input'"
          placeholder="{{labelNoTranslation ? placeholderInput : (placeholderInput | translate)}}"
          maxlength="{{maxlength}}" minlength="{{minlength}}" formControlName="{{valueInput}}" [readonly]="readonly">
        <input *ngIf="selector == 'number'" matInput [appNumbersOnly]="true"
          [ngClass]="hasOneLanguage ? 'hasOneLanguage form-input' : 'form-input'"
          placeholder="{{labelNoTranslation ? placeholderInput : (placeholderInput | translate)}}"
          formControlName="{{valueInput}}" [readonly]="readonly">
        <input *ngIf="selector == 'decimal'" matInput
          [ngClass]="hasOneLanguage ? 'hasOneLanguage form-input' : 'form-input'"
          placeholder="{{labelNoTranslation ? placeholderInput : (placeholderInput | translate)}}" type="number"
          step="0.01" formControlName="{{valueInput}}" [readonly]="readonly">

        <mat-form-field *ngIf="selector == 'date'">
          <input matInput [matDatepicker]="picker" formControlName="{{valueInput}}" readonly>
          <mat-datepicker-toggle matSuffix [for]="picker" [class.toggleDisabled]="readonly" [disabled]="readonly">
            <!--          TODO: change icon-->
            <!--          <mat-icon matDatepickerToggleIcon></mat-icon>-->
          </mat-datepicker-toggle>
          <mat-datepicker #picker></mat-datepicker>
        </mat-form-field>

        <framework-uploader *ngIf="selector == 'image'" [formControlName]="valueInput"
          [showProgress]="true" [fileType]="'image'"></framework-uploader>
        <framework-uploader *ngIf="selector == 'video'" [formControlName]="valueInput"
          [showProgress]="true" [path]="uploadPath" [fileType]="'video'"></framework-uploader>

        <ng-select class="form-control" *ngIf="selector == 'select'" [searchable]="true" formControlName="{{valueInput}}"  [hideSelected]="true"
          [multiple]="selectMultiple" [readonly]="readonly">
          <ng-option *ngFor="let item of selectDataToLoop" [value]="item[selectValue]">{{ item[selectDisplay] }}
          </ng-option>
        </ng-select>
        <textarea *ngIf="selector == 'textarea'" matInput rows="7"
          [ngClass]="hasOneLanguage ? 'hasOneLanguage form-control' : 'form-control'"
          placeholder="{{labelNoTranslation ? placeholderInput : (placeholderInput | translate)}}"
          formControlName="{{valueInput}}" maxlength="{{maxlength}}" minlength="{{minlength}}" [readonly]="readonly"></textarea>
          <ng-container *ngIf="insidePopup; else other_content">
            <editor [apiKey]="tinymceConfigService.getKey()" popupTinymceEditor *ngIf="selector === 'editor'" class="editorDesign" [init]="editorConfigs"
            formControlName="{{valueInput}}" [disabled]="readonly"></editor>

          </ng-container>
          <ng-template #other_content>
            <editor [apiKey]="tinymceConfigService.getKey()" popupTinymceEditor *ngIf="selector === 'editor'" class="editorDesign" [init]="editorConfigs"
            formControlName="{{valueInput}}" [disabled]="readonly"></editor>
          </ng-template>
        <span *ngIf="maxlength"
        class="form-text messageCount">{{form.get('translations').get(curLang).get(valueInput).value?.length > 0 ?
          form.get('translations').get(curLang).get(valueInput).value.length : '0' }}/{{maxlength}}</span>

        <!--            added this <small> for design purposes-->
        <small
          *ngIf="!requiredOnly && !form.get('translations').get(curLang).get(valueInput)['errorMessage'] && !this.form.get('translations').invalid"></small>

        <small *ngIf="!requiredOnly && form.get('translations').get(curLang).get(valueInput)['errorMessage']"
          class="form-text text-danger">{{form.get('translations').get(curLang).get(valueInput)['errorMessage']}}
          <br /></small>

        <small class="form-text text-danger"
          *ngIf="requiredOnly && form.get('translations').get(curLang).get(valueInput).hasError('required') && (form.get('translations').get(curLang).get(valueInput).touched || form.get('translations').get(curLang).get(valueInput).dirty)">{{"validations.required_field"
          | translate}}</small>
        <small class="form-text text-danger"
          *ngIf="maxlength  && (form.get('translations').get(curLang).get(valueInput).touched && form.get('translations').get(curLang).get(valueInput).hasError('maxlength'))">{{"validations.maxCharLength"
          | translate : { maxnumber: maxlength } }}</small>
        <small class="form-text text-danger"
          *ngIf="minlength  && (form.get('translations').get(curLang).get(valueInput).touched && form.get('translations').get(curLang).get(valueInput).hasError('minlength'))">{{"validations.minCharLength"
          | translate : { minnumber: minlength } }} {{minlength}}</small>
        <small class="form-text text-danger"
          *ngIf="pattern && (form.get('translations').get(curLang).get(valueInput).touched && form.get('translations').get(curLang).get(valueInput).hasError('pattern'))">{{"validations.pattern"
          | translate}}</small>
        <!--TODO: error message needs to be enhanced - if required show message - if other validation message, the below should be changed-->
        <small class="form-text text-danger"
          *ngIf="!requiredOnly && !form.get('translations').get(curLang).get(valueInput)['errorMessage'] && this.form.get('translations').invalid">
          <span *ngFor="let language of languagesNoCurrent; let index = index">
            <span *ngIf="form.get('translations').get(language.code).get(valueInput)['errorMessage']">
              {{"validations.translations_required" | translate: { language: (language?.translations?.[curLang]?.name || language.name || language.code) } }}
              <br>
            </span>
          </span>
        </small>
        <small class="form-text text-danger"
          *ngIf="requiredOnly && !form.get('translations').get(curLang).get(valueInput).hasError('required') && this.form.get('translations').invalid">
          <span *ngFor="let language of languagesNoCurrent; let index = index">

            <span *ngIf="form.get('translations').get(language.code).get(valueInput).hasError('required')">
              {{"validations.translations_required" | translate: { language: (language?.translations?.[curLang]?.name || language.name || language.code) } }}
              <br>
            </span>
          </span>
        </small>
        <small class="form-text text-danger"
          *ngIf="pattern && !form.get('translations').get(curLang).get(valueInput).hasError('pattern') && this.form.get('translations').invalid">
          <span *ngFor="let language of languagesNoCurrent; let index = index">

            <span *ngIf="form.get('translations').get(language.code).get(valueInput).hasError('pattern')">
               {{language.name || language.code}} {{ "validations.hasInvalidPattern" | translate }}
              <br>
            </span>
          </span>
        </small>
      </div>
    </div>
    <a [hidden]="hasOneLanguage" class="translationPopupButton" data-bs-toggle="modal" (click)="hideAllOtherModals()"
       [attr.data-bs-target]="'#translationModal' + currentComponentCount">
      <span class="foo-icon foo-language-primary"></span>
    </a>
    <!--  data-bs-backdrop is now true(by default) after bootstrap update because setting it to false is making it not scroll when opened in material popup  -->
    <div class="modal" id="translationModal{{currentComponentCount}}">
      <div class="modal-dialog">
        <div class="modal-content">
          <div class="container"></div>
          <div class="modal-body">
            <div class="col-12 mb-20" *ngFor="let language of languagesNoCurrent">
              <h2 class="form-label" [class.d-inline-flex]="labelInputTooltip"><span class="lang" *ngIf="selector === 'editor'">{{ language.code }}</span> {{ labelNoTranslation ? labelInput : (labelInput | translate) }}<span *ngIf="fieldRequired&&isRequiredField(language.code,valueInput)">*</span>
                <ng-container *ngIf="labelInputTooltip">
                  <div class="foo-icon foo-stack ml-1 foo-w-17 foo-h-17" #tooltip="matTooltip" matTooltipPosition="above"
                       [matTooltip]="labelInputTooltip | translate">
                    <span class="foo-icon foo-circle foo-shadow-primary w-100 h-100"></span>
                    <span class="foo-icon foo-form-info-primary"></span>
                  </div>
                </ng-container>
              </h2>
              <div formGroupName="{{language.code}}">
                <span class="inputLanguageInner" *ngIf="selector !== 'editor'">{{language.code}}</span>
                <input *ngIf="selector == null || selector == 'input'" type="text" matInput class="form-input"
                  maxlength="{{maxlength}}" minlength="{{minlength}}"
                  placeholder="{{labelNoTranslation ? placeholderInput : (placeholderInput | translate)}}"
                  formControlName="{{valueInput}}" [readonly]="readonly">
                <input *ngIf="selector == 'number'" matInput [appNumbersOnly]="true" class="form-input"
                  placeholder="{{labelNoTranslation ? placeholderInput : (placeholderInput | translate)}}"
                  formControlName="{{valueInput}}" [readonly]="readonly">
                <input *ngIf="selector == 'decimal'" matInput class="form-input"
                  placeholder="{{labelNoTranslation ? placeholderInput : (placeholderInput | translate)}}" type="number"
                  step="0.01" formControlName="{{valueInput}}" [readonly]="readonly">

                <textarea *ngIf="selector == 'textarea'" matInput rows="7" class="form-control"
                  placeholder="{{labelNoTranslation ? placeholderInput : (placeholderInput | translate)}}"
                  formControlName="{{valueInput}}" [maxlength]="maxlength" [minlength]="minlength" [readonly]="readonly"></textarea>
                <ng-container *ngIf="insidePopup; else other_content">
                  <editor [apiKey]="tinymceConfigService.getKey()" popupTinymceEditor *ngIf="selector === 'editor'" class="editorDesign" [init]="editorConfigs"
                  formControlName="{{valueInput}}" [disabled]="readonly"></editor>
                </ng-container>
                <ng-template #other_content>
                  <editor [apiKey]="tinymceConfigService.getKey()" popupTinymceEditor *ngIf="selector === 'editor'" class="editorDesign" [init]="editorConfigs"
                  formControlName="{{valueInput}}" [disabled]="readonly"></editor>
                </ng-template>
                <span *ngIf="maxlength"
                class="form-text messageCount w-auto">{{form.get('translations').get(language.code).get(valueInput).value?.length > 0 ?
                form.get('translations').get(language.code).get(valueInput).value.length : '0' }}/{{maxlength}}</span>

                <mat-form-field *ngIf="selector == 'date'">
                  <input matInput [matDatepicker]="picker" formControlName="{{valueInput}}" readonly>
                  <mat-datepicker-toggle matSuffix [for]="picker" [class.toggleDisabled]="readonly" [disabled]="readonly"></mat-datepicker-toggle>
                  <mat-datepicker #picker></mat-datepicker>
                </mat-form-field>

                <framework-uploader *ngIf="selector == 'image'" [formControlName]="valueInput" [fileType]="'image'"
                  [showProgress]="true"></framework-uploader>
                <framework-uploader *ngIf="selector == 'video'" [formControlName]="valueInput"
                  [showProgress]="true" [path]="uploadPath" [fileType]="'video'"></framework-uploader>

                <ng-select class="form-control" *ngIf="selector == 'select'" [searchable]="true"  [hideSelected]="true"
                  formControlName="{{valueInput}}" [multiple]="selectMultiple" [readonly]="readonly">
                  <ng-option *ngFor="let item of selectDataToLoop" [value]="item[selectValue]">{{ item[selectDisplay] }}
                  </ng-option>
                </ng-select>

              </div>
              <small class="form-text text-danger"
                *ngIf="!requiredOnly && form.get('translations').get(language.code).get(valueInput)['errorMessage']">{{form.get('translations').get(language.code).get(valueInput)['errorMessage']}}
                <br /></small>

              <small class="form-text text-danger"
                *ngIf="requiredOnly && form.get('translations').get(language.code).get(valueInput).hasError('required') && (form.get('translations').get(language.code).get(valueInput).touched || form.get('translations').get(language.code).get(valueInput).dirty)">{{"validations.required_field"
                | translate}}</small>
              <small class="form-text text-danger"
                *ngIf="maxlength  && (form.get('translations').get(language.code).get(valueInput).touched && form.get('translations').get(language.code).get(valueInput).hasError('maxlength'))">{{"validations.maxCharLength"
                | translate: { maxnumber: maxlength } }}</small>
              <small class="form-text text-danger"
                *ngIf="minlength  && (form.get('translations').get(language.code).get(valueInput).touched && form.get('translations').get(language.code).get(valueInput).hasError('minlength'))">{{"validations.minCharLength"
                | translate: { minnumber: minlength } }}</small>
              <small class="form-text text-danger"
                *ngIf="pattern  && (form.get('translations').get(language.code).get(valueInput).touched && form.get('translations').get(language.code).get(valueInput).hasError('pattern'))">{{"validations.pattern"
                | translate}}</small>
            </div>
          </div>

          <div class="modal-footer">
            <button mat-button data-bs-dismiss="modal" class="foo-btn-outline-secondary">
              {{ "general.done" | translate }}
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</form>
