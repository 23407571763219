<button class="close-icon" mat-dialog-close><span class="foo-icon foo-close-dark"></span></button>
<div mat-dialog-title >
  <p>{{data.title}}</p>
</div>
<mat-dialog-content class="mt-10">
  <div [innerHtml]="data.text"></div>
</mat-dialog-content>
<mat-dialog-actions>
  <button mat-button class="foo-btn-outline-secondary" [mat-dialog-close]="true">{{data.button}}</button>
   <button mat-button mat-dialog-close class="foo-btn-outline-secondary" ng-if="!hideCancelButton">{{'general.cancel' | translate}}</button>
</mat-dialog-actions>

