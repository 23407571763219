<div class="message-popup">
    <div class="icon" *ngIf="data.message_type && data.message_type=='neutral'">
        <span class="foo-icon foo-info-primary"></span>
    </div>
    <div class="icon" *ngIf="!data.message_type || data.message_type=='success'">
        <span class="foo-icon foo-success-pass-check foo-w-24 foo-h-24"></span>
    </div>
    <div class="icon" *ngIf="data.message_type && data.message_type=='error'">
        <span class="foo-icon foo-failed-pass-check foo-w-24 foo-h-24"></span>
    </div>
    <div class="title" *ngIf="data.title">{{data.title}}</div>
    <h2 class="main-text mb-0" *ngIf="data.main_text">{{data.main_text}}</h2>
    <div class="sub-text" *ngIf="data.sub_text">{{data.sub_text}}</div>
    <button mat-button [mat-dialog-close]="true" class="foo-btn-primary ">{{data.button_text}}</button>
</div>
