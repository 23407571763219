<div class="top-title-with-buttons d-block">
  <div class="buttons-container" *ngIf="btnList?.length && !hideBtnListOnDesktop">
    <ng-container *ngFor="let btnItem of btnList">
      <button [disabled]="dataShimmer || isBtnListDisabled || btnItem?.isDisabled"
          (click)="$event.stopPropagation();btnFunctionCalled(btnItem.fctName, btnItem?.fctData)"
          *ngIf="btnItem?.isShown && !btnItem?.isHiddenOnDesktop"
          class="{{btnItem.className ? btnItem.className : 'foo-btn-primary'}}">
          <img *ngIf="btnItem.imagePath" src="{{btnItem.imagePath}}"
              class="svg-color-filter" [alt]="btnItem.imagePath" />
          <span *ngIf="btnItem.icon" class="foo-icon foo-{{btnItem.icon}}"></span>
          <span *ngIf="btnItem.title">{{btnItem.title}}</span>
      </button>
    </ng-container>
  </div>
  <div class="row responsive-buttons responsive-buttons-container" (window:resize)="setPageContentMargin()" *ngIf="btnList?.length && !hideBtnListOnMobile">
    <div class="buttons-container flex-row flex-wrap align-items-center justify-content-center">
      <ng-container *ngFor="let btnItem of btnList">
        <button [disabled]="dataShimmer || isBtnListDisabled || btnItem?.isDisabled"
            (click)="$event.stopPropagation();btnFunctionCalled(btnItem.fctName, btnItem?.fctData)"
            *ngIf="btnItem?.isShown && !btnItem?.isHiddenOnMobile"
            [ngClass]="btnItem.responsiveClassName ? btnItem.responsiveClassName : (btnItem.className ? btnItem.className : 'foo-btn-primary')"
            class="m-2">
            <img *ngIf="btnItem.imagePath" src="{{btnItem.imagePath}}"
                class="svg-color-filter" [alt]="btnItem.imagePath" />
            <span *ngIf="btnItem.icon" class="foo-icon foo-{{btnItem.icon}}"></span>
            <span *ngIf="btnItem.title">{{btnItem.title}}</span>
        </button>
      </ng-container>
    </div>
  </div>
</div>