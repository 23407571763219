import {Component, OnInit, EventEmitter, Output, HostListener, Input, SimpleChanges, OnDestroy} from '@angular/core';
import { Router, NavigationEnd } from '@angular/router';
import {MenuItem} from '../../interfaces/menu-item';
import { TokenStorage } from '../../services/token-storage.service';
import { AuthenticateService } from '../../services/authenticate.service';
import {LoaderService} from '../../services/loader/loader.service';
import {AlertService} from '../../services/alert.service';
import {PermissionsService} from '../../services/permissions.service';
import { RouteService } from '../../services/route.service';
import _, {find, map} from 'lodash';
import {filter} from "rxjs/operators";
import {Subscription} from "rxjs";
import { DomainService } from '../../services/domain.service';
import { get } from 'lodash';

export enum Environments {
  develop = 'develop',
  sandbox = 'sandbox',
  production = 'production'
}
@Component({
  selector: 'app-left-menu',
  templateUrl: './left-menu.component.html',
  styleUrls: ['./left-menu.component.scss']
})
export class LeftMenuComponent implements OnInit, OnDestroy {
  currentUrl: string;
  mainUrl: string;
  menuItems = [];
  adminItems: MenuItem[] = [];
  deviceItems: MenuItem[] = [];
  myAccountMenuItems: MenuItem[] = [];
  topLeftMenuItems: MenuItem[] = [];
  menuClosed = false;
  hideMenu = false;
  personName: string;
  firstTimeOpen = true;
  @Input() LangChanged;
  @Output() onMenuClosedEvent = new EventEmitter<boolean>();
  @Output() LangChangedEvent = new EventEmitter<string>();
  @Input() showLang: boolean;
  logo: any;
  userLogo: string;
  userType: string;
  languages: any;
  subscriptions = new Subscription();
  leftMenuSearchBarItems: any[] = [];
  environment: Environments = this.tokenStorage.getValueForKey('env_tag') as Environments;

  get environmentBackgroundColor() {
    return this.tokenStorage.getValueForKey('env_tag_color');
  }

  constructor(protected router: Router,
    protected authenticateService: AuthenticateService,
    protected tokenStorage: TokenStorage,
    protected loaderService: LoaderService,
    protected alert: AlertService,
    protected permissions: PermissionsService,
    protected routerService: RouteService,
    public domainService: DomainService
  ) {
    const url = this.getUrlWithoutPrefix(this.router.url || '');
    this.currentUrl = url;
    this.mainUrl = url.split('/').slice(0, 2).join('/');
    this.subscriptions.add(router.events
      .pipe(filter(event => event instanceof NavigationEnd))
      .subscribe((event: NavigationEnd) => {
        const eventUrl = this.getUrlWithoutPrefix(this.router.url || '');
        this.currentUrl = eventUrl;
        this.mainUrl = eventUrl.split('/').slice(0, 2).join('/');
        this.firstTimeOpen = true;
      }));
  }
  public innerWidth: any;
  curLang: string;
  lastLogin;

  getUrlWithoutPrefix(url: string): string {
    const prefix = window['prefix'];
    const urlParts = url.split('/');
    if (prefix && urlParts.length && urlParts[0] === prefix) {
      urlParts.shift();
      url = '/' + urlParts.join('/');
    } else if (prefix && urlParts.length >= 2 && urlParts[0] === '' && urlParts[1] ===  prefix) {
      urlParts.shift();
      urlParts.shift();
      url = '/' + urlParts.join('/');
    }
    return url.split('?')[0];
  }

  getRoutePath(menuItem: any): string {
    if ((this.routerService as any).isNavigationPeventedOnRoute(menuItem)) {
      return null;
    } else {
      return this.domainService.getPrefixedURL(menuItem.path);
    }
  }

  @HostListener('window:resize', ['$event'])
  onResize(event) {
    this.innerWidth = window.innerWidth;
    this.hideMenuFunc();
  }

  ngOnInit(): void {
    this.menuClosed = this.routerService.menuClosed;
    this.onMenuClosedEvent.emit(this.menuClosed);

    this.languages = window['languages'];
    const user = JSON.parse(this.tokenStorage.getUser());
    const config = JSON.parse(this.tokenStorage.getWebConfig());
    this.logo = config?.logo;
    this.userLogo = user?.related_info?.image;
    this.userType = user?.user_type;
    this.personName = user.profile["fname"];
    this.lastLogin = JSON.parse(this.tokenStorage.getLastLogin());
    this.curLang = this.tokenStorage.getCurrentLanguage();
    this.innerWidth = window.innerWidth;
    this.menuItems = this.routerService.initMenu('left_menu');
    this.adminItems = this.routerService.initMenu('admin_menu');
    this.deviceItems = this.routerService.initMenu('device_menu');
    this.myAccountMenuItems = this.routerService.initMenu("my_account_menu");
    this.topLeftMenuItems = this.routerService.initMenu("top_left_menu");
    this.hideMenuFunc();
    this.leftMenuSearchBarItems = this.restructureLeftMenuSearchBarItems(_.cloneDeep([...this.menuItems, ...this.topLeftMenuItems])).map((menuItem: any) => {
      if (menuItem?.isChild) {
        const parentMenuId = this.routerService.menu.find((item: any) => (item?.main_controller === menuItem?.main_controller) && (item?.menu_url === menuItem?.menu_url))?.menu_parent_id;
        const parentItem = this.routerService.menu.find((item: any) => item.id === parentMenuId);
        if (parentItem) {
          menuItem.parentItemTitle = parentItem.title;
          menuItem.translatedParentItemTitle =  this.routerService.getDataFromTranslations(parentItem, 'title');
        }
      }
      return menuItem;
    }).filter((menuItem: any) => {
      return menuItem?.menu_url !== 'javascript:;'
    });

    if(JSON.parse(localStorage.getItem("menuClosed"))){
      this.menuClosed = !this.menuClosed;
      this.routerService.menuClosed = this.menuClosed;
      this.onMenuClosedEvent.emit(this.menuClosed);
    }

    this.tokenStorage.userDetailsSubject.subscribe(user => {
      if (user) {
        this.personName = get(user, 'profile.fname') || '';
        this.userLogo = get(user, 'related_info.image') || '';
      }
    });
  }

  restructureLeftMenuSearchBarItems(menuItems: any[]): any[] {
    const restructuredItems: any[] = [];

    for (const item of menuItems) {
      restructuredItems.push(item);

      if (item?.submenu?.length) {
        for (const subItem of item.submenu) {
          restructuredItems.push(subItem);
        }
        item.submenu = null;
      }
    }

    return restructuredItems;
  }

  hideMenuFunc() {
    if (this.innerWidth > 1024) {
      this.hideMenu = true;
    }else{
      this.hideMenu = false;
    }
    this.menuClosed = false;
    this.onMenuClosedEvent.emit(this.menuClosed);
  }

  toggleMenu($event){
    //check if event false remove class menuclosed from page-content-wrapper class in pages.component.html
    if($event){
      this.menuClosed = !this.menuClosed;
      this.routerService.menuClosed = this.menuClosed;
      this.onMenuClosedEvent.emit(this.menuClosed);
      localStorage.setItem('menuClosed', JSON.stringify(this.menuClosed));
    }else{
      this.menuClosed = $event;
    }
  }

  itemToggleMenu() {
    if(this.innerWidth <= 1024 && this.menuClosed){
      this.menuClosed = !this.menuClosed;
      this.routerService.menuClosed = this.menuClosed;
      this.onMenuClosedEvent.emit(this.menuClosed);
    }
  }

  onItemClicked(event: any, menuItem: any): void {
    if ((this.routerService as any).isNavigationPeventedOnRoute(menuItem)) {
      (this.routerService as any).handleCustomRoute(menuItem);
    }
    this.itemToggleMenu();
  }

  itemToggleSubMenu(){
    if(this.innerWidth > 1024 && this.menuClosed){
      this.menuClosed = !this.menuClosed;
      this.routerService.menuClosed = this.menuClosed;
      this.onMenuClosedEvent.emit(this.menuClosed);
    }
  }

  itemParentMenu(item){
    this.firstTimeOpen = false;
    map(this.menuItems, (menuItem) => {
      if(menuItem.path != item.path) {
        return menuItem.isOpen = false;
      }
    });
    item.isOpen = !item.isOpen;
    // this.itemToggleSubMenu();
  }
  checkIfActivatedChildRoute(currentUrl, item): boolean {
    let activated = false;
    if(currentUrl === item.path){
      activated = true;
    }else{
      if(item.submenu){
        activated = find(item.submenu, (subitem) => {
          return (currentUrl === subitem.path || this.mainUrl == subitem.path);
        });
      }
    }
    if(activated && this.firstTimeOpen){
      item.isOpen = true;
    }
    return activated;
  }
  useLanguage(){
    var language = "en";
    if(this.tokenStorage.getCurrentLanguage() == "en"){
      language = "ar";
    }
    this.LangChangedEvent.emit(language);
    this.tokenStorage.setCurrentLanguage(language);
  }
  ngOnChanges(changes: SimpleChanges) {
    this.curLang = this.LangChanged;
  }
  onLogout() {
    this.loaderService.visibility = true;
    this.authenticateService.logout().subscribe(
      (data) => {
        if (data["status"] === 'OK') {
          this.tokenStorage.clear();
          this.routerService.clearParams();
          window.location.href = (window['prefix'] ? `/${window['prefix']}` : '') +'/login';
          this.authenticateService.cancelPendingRequests();
        } else {
          this.loaderService.visibility = false;
          this.alert.emitAlert({type:"danger", text: data["message"]});
        }

      },
      error => {
        this.loaderService.visibility = false;
        this.alert.emitAlert({type:"danger", text: error["message"]});
      });
  }

  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }
}
