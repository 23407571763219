<div class="max-width-100" [class.border-top]="boxView"></div>
<div [class.pt-18]="boxView"
    [style.width.%]="detailsObject && detailsObject?.containerWidth ? detailsObject?.containerWidth : '100'"
    class="pt-16">
    <div class="d-flex display-inline-mobile shimmer-row">
        <ng-container *ngFor="let colDetailKey of getObjKeys(detailsObject); let first = first">
          <div [style.flex-basis]="!(detailsObject?.lineBreakKeyValue) ? getSecureStyle(calculateFlexBasisForGapsBetweenColumns(detailsObject)) : ''"
               [class.pr-70]="detailsObject?.lineBreakKeyValue"
               *ngIf="!first && showSeparator && columnHasValues(detailsObject[colDetailKey])" class="column-border-left"></div>

            <div [style.flex-basis]="getSecureStyle(calcFlexBasis(detailsObject))">
                <table>
                    <tbody [class.shimmer-animate]="isArray(isShimmeringDetails) ? isShimmeringDetails[index] : isShimmeringDetails">
                        <tr [ngClass]="(!lastRow && !rowDetailKey.value) ? 'd-none' : ''" *ngFor="let rowDetailKey of detailsObject[colDetailKey]; let lastRow = last" class="shimmer-row">
                            <td [class.pb-10]="!lastRow && !detailsObject?.lineBreakKeyValue" [class.pb-1]="detailsObject?.lineBreakKeyValue" class="pr-15" [class.labelValueAsSeparateLines]="detailsObject?.lineBreakKeyValue">
                                <span
                                    class="shimmer-item shimmer-i-block {{ detailsObject?.keyClassName ? detailsObject.keyClassName : 'span-left-side'}} {{ rowDetailKey?.keyClassName ? rowDetailKey.keyClassName : ''}}">{{
                                    (detailsObject?.key ? (rowDetailKey | stringobject: detailsObject.key) :
                                    rowDetailKey?.key ? rowDetailKey.key : '') | translate}}</span>
                            </td>
                            <td [class.pb-10]="!lastRow && !detailsObject?.lineBreakKeyValue" [ngClass]="{'labelValueAsSeparateLines pb-15' : detailsObject?.lineBreakKeyValue}">
                                <b
                                    class="shimmer-item shimmer-i-block {{ detailsObject?.valueClassName ? detailsObject.valueClassName : 'span-right-side'}} {{ rowDetailKey?.valueClassName ? rowDetailKey.valueClassName : ''}}">
                                    {{ (detailsObject?.data || detailsObject?.getExpansionPanelData) ? (detailsObject?.value ?
                                    (rowDetailKey | stringobject: detailsObject.value) : rowDetailKey.value) :
                                    rowDetailKey?.formatter ? rowDetailKey.formatter(item[rowDetailKey.value]) : ((item | stringobject: rowDetailKey.value) ? (item | stringobject: rowDetailKey.value) : "-") }}</b>
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>

        </ng-container>
    </div>
</div>

<div *ngIf="detailsObject?.getExpansionPanelData && objectIsEmpty()">
    <framework-no-data></framework-no-data>
</div>
