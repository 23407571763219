import { startWith } from 'rxjs/operators';
import { TranslateService } from '@ngx-translate/core';
import {
  AbstractControl,
  UntypedFormArray,
  UntypedFormControl,
  ValidationErrors,
  ValidatorFn,
} from '@angular/forms';
import { Injectable } from '@angular/core';
import { validateIBAN } from 'ibantools';
import { get } from 'lodash';
import { CardValidator } from './card-validator.service';
import {ValidatorPatterns, ValidatorPatternsConfig} from "../utils/validators.utils";

@Injectable({
  providedIn: 'root',
})
export class CustomValidatorsService {
  ibanErrorCodes = [];

  constructor(
    private translate: TranslateService,
    private cardValidation: CardValidator
  ) {
    this.translate.onLangChange
      .pipe(
        startWith({
          lang: this.translate.currentLang,
        })
      )
      .subscribe(({ lang }) => {
        this.initIbanErrorCodes();
      });
  }

  public notEmptyStringValidator(control: UntypedFormControl) {
    const isStringEmpty = (control.value || '').trim().length === 0;
    return isStringEmpty ? { notEmptyString: true } : null;
  }

  public onlyNumbers(control: UntypedFormControl) {
    const regex = new RegExp('^[0-9.]');
    if ((control.value) && !regex.test(control.value)) {
      return { onlyNumbers: true };
    }
    return null;
  }

  public email(control: UntypedFormControl): any {
    const regex = new RegExp('^[a-zA-Z0-9.!#$%&\'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+\\.[a-zA-Z0-9-]+(?:\\.[a-zA-Z0-9-]+)*$');
    if ((control.value) && !regex.test(control.value)) {
      return { email: true };
    }
    return null;
  }

  public alphabetsOnly(control: UntypedFormControl) {
    const regex = new RegExp('^[a-zA-Z ]*$');
    const regexSpaces = new RegExp('^ +$');
    if (regexSpaces.test(control.value) || !regex.test(control.value)){
      return { alphabetsOnly: true };
    }
    return null;
  }

  public noSpecialCharacters(control: UntypedFormControl): any {
    const regex = new RegExp(/^[\s\da-zA-Z,-.\p{Arabic}\p{N}]+$/);
    if (!regex.test(control.value)) {
      return { noSpecialCharacters: true };
    }
    return null;
  }


  public noteCharactersOnly(limit: number) {
    return (control: UntypedFormControl) => {
      if ((control.value || '').length > limit) {
        return { noteCharactersOnly: true };
      }
      const regex = new RegExp('^[._\\/)(a-zA-Z)(0-9)]');
      if (!regex.test(control.value)) {
        return { noteCharactersOnly: true };
      }

      return null;
    };
  }

  initIbanErrorCodes(): void {
    this.ibanErrorCodes = [
      this.translate.instant('ibanErrors.0'),
      this.translate.instant('ibanErrors.1'),
      this.translate.instant('ibanErrors.2'),
      this.translate.instant('ibanErrors.3'),
      this.translate.instant('ibanErrors.4'),
      this.translate.instant('ibanErrors.5'),
    ];
  }

  iban() {
    const self = this;
    return (control: AbstractControl) => {
      let iban = control.value;
      var country = iban.substring(0, 2);
      if (country.toUpperCase() != 'AE') {
        iban = 'AE' + iban;
      }
      var IbanVal = validateIBAN(iban);
      if (IbanVal.valid) {
        return null;
      } else {
        // IbanVal.errorCodes.forEach((error) => {
        //   console.log(this.ibanErrorCodes[error]);
        // });
        return {
          iban:
            IbanVal.errorCodes.map((error) => self.ibanErrorCodes[error]) ||
            true,
        };
      }
    };
  }

  invalidCardNumber(): ValidatorFn {
    return (formArray: UntypedFormArray) => {
      let valid = !formArray.controls.find(
        (control: UntypedFormControl) => get(control.value, 'length') !== 4
      );
      if (valid) {
        const card_number = formArray.controls
          .map((control) => control.value)
          .join('');
        valid = this.cardValidation.LuhnValidation(card_number);
      }
      return valid ? null : { invalidCardNumber: 'Invalid Card' };
    };
  }

  ValidateMaxWords(limtofWords: number): ValidatorFn {
    return (control: AbstractControl): { [key: string]: boolean } | null => {
      if(control.value) {
        if (control.value.split(' ').length > limtofWords) {
          return { 'maxwords': true };
        }
      }
      return null;
    };
  }

  MatchValidator(source: string, target: string): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
      const sourceCtrl = control.get(source);
      const targetCtrl = control.get(target);

      return sourceCtrl && targetCtrl && sourceCtrl.value !== targetCtrl.value
        ? { mismatch: true }
        : null;
    };
  }

  PasswordStrength(key, value): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {

      return value && value.test(control.value) === false
        ? { [key]: true }
        : null;
    };
  }

  lessThan(minControlName: string, maxControlName: string) {
    return (control: AbstractControl): { [key: string]: boolean } | null => {
      if (!control.parent) {
        // Control is not yet associated with a parent.
        return null;
      }
      const minControl = control.parent.get(minControlName).value;
      const maxControl = control.parent.get(maxControlName).value;

      if ((minControl != null && minControl != '' && maxControl != null && maxControl != '' && (parseFloat(minControl) <= parseFloat(maxControl))) ||
        (minControl == null || minControl == '' || maxControl == null || maxControl == '')) {
        control.parent.get(minControlName).setErrors(null);
        control.parent.get(maxControlName).setErrors(null);
        return null;
      }
      control.parent.get(minControlName).setErrors({ 'lessthan': true });
      control.parent.get(maxControlName).setErrors({ 'lessthan': true });

      return {
        'lessthan': true
      }
    };
  }

  removeErrors(keys: string[], control: AbstractControl) {
    if (!control || !keys || keys.length === 0) {
      return;
    }

    const remainingErrors = keys.reduce((errors, key) => {
      delete errors[key];
      return errors;
    }, { ...control.errors });

    control.setErrors(remainingErrors);

    if (Object.keys(control.errors || {}).length === 0) {
      control.setErrors(null);
    }
  }
  //TODO: add extra arg -> for less than or "EQUAL" or create a new validator - the current validator checks less than only
  versionLessThan(minControlName: string, maxControlName: string) {
    return (control: AbstractControl): { [key: string]: boolean } | null => {
      if (!control.parent) {
        // Control is not yet associated with a parent.
        return null;
      }
      let minControl = JSON.parse(JSON.stringify(control.parent.get(minControlName).value));
      let maxControl = JSON.parse(JSON.stringify(control.parent.get(maxControlName).value));
      let minControlValid = true;
      let maxControlValid = true;
      let islessthan = null;
      if (typeof maxControl !== 'string' || typeof minControl !== 'string') {
        islessthan = false
      } else if (maxControl && minControl) {
        let pattern = '^(?!\\.)(?!.*\\.$)(?!.*\\.\\.)[0-9_.]+$';
        const regex = new RegExp(pattern);
        if (!regex.test(minControl) && minControl != '') {
          minControlValid = false;
        }
        if (!regex.test(maxControl) && maxControl != '') {
          maxControlValid = false;
        }
        maxControl = maxControl ? maxControl.split('.') : null;
        minControl = minControl ? minControl.split('.') : null;
        const k = Math.min(maxControl.length, minControl.length);
        for (let i = 0; i < k; ++i) {
          maxControl[i] = parseInt(maxControl[i], 10);
          minControl[i] = parseInt(minControl[i], 10);
          if (maxControl[i] > minControl[i]) {
            islessthan = true;
            break;
          }
          if (maxControl[i] < minControl[i]) {
            islessthan = false;
            break;
          }
        }
        if (islessthan == null) {
          islessthan = maxControl.length == minControl.length ? false : (maxControl.length < minControl.length ? false : true);
        }
      }

      if ((minControl != null && minControl != '' && maxControl != null && maxControl != '' && islessthan) ||
        (minControl == null || minControl == '' || maxControl == null || maxControl == '') || (!minControlValid || !maxControlValid)) {
        this.removeErrors(['versionlessthan'], control.parent.get(minControlName));
        this.removeErrors(['versionlessthan'], control.parent.get(maxControlName));

        return null;
      }
      control.parent.get(minControlName).setErrors({'versionlessthan': true});
      control.parent.get(maxControlName).setErrors({'versionlessthan': true});

      return {
        'versionlessthan': true
      }
    };
  }

  public checkPattern(type: ValidatorPatterns | string , pattern : string | RegExp = '') : any {
    const regex = new RegExp(pattern ?? ValidatorPatternsConfig?.[type]);

    return (control: AbstractControl): any => {
      if (!regex.test(control.value) && control.dirty && control.value !== '') {
        return { pattern: type || true };
      }
      return null;
    };
  }

  public greaterThanValue(value: number): any {
    return (control: UntypedFormControl) => {
      if (!isNaN(parseFloat(control.value)) && parseFloat(control.value) <= value) {
        return {greaterThanValue: true};
      }
      return null;
    };
  }

  public lessThanValue(value: number): any {
    return (control: UntypedFormControl) => {
      if (!isNaN(parseFloat(control.value)) && parseFloat(control.value) >= value) {
        return {lessThanValue: true};
      }
      return null;
    };
  }
}
