import {
  HttpErrorResponse,
  HttpEvent,
  HttpHandlerFn,
  HttpRequest,
  HttpResponse,
} from "@angular/common/http";
import {EMPTY, Observable, throwError} from "rxjs";
import {inject} from "@angular/core";
import {
  AlertService,
  AuthenticateService,
  LoaderService, MakeCallService,
  TokenStorage
} from "foo-framework";
import {Router} from "@angular/router";
import {TranslateService} from "@ngx-translate/core";
import {catchError, finalize, takeUntil, tap} from "rxjs/operators";

export function AuthInterceptor(req: HttpRequest<any>, next: HttpHandlerFn) : Observable<HttpEvent<any>> {
  let alert_count = 0;
  let shimmers = [];
  const loaderService = inject(LoaderService);
  const tokenStorage = inject(TokenStorage);
  const authenticateService = inject(AuthenticateService);
  const router = inject(Router);
  const translate = inject(TranslateService);
  const alert = inject(AlertService);
  const makeCallService = inject(MakeCallService);
  const sessionExpiredRedirection = () => {
      let currentRoute = router.url;
      tokenStorage.clear();

      if (currentRoute != ((window['prefix'] ? `/${window['prefix']}` : '') + "/home") && currentRoute != ((window['prefix'] ? `/${window['prefix']}` : '') + "/")) {
          window.location.href = (window['prefix'] ? `/${window['prefix']}` : '') + "/login?returnUrl=" + currentRoute;
      } else {
          window.location.href = (window['prefix'] ? `/${window['prefix']}` : '') + "/login";
      }

      authenticateService.cancelPendingRequests();
  };

  let updateIsLoading = req.headers.has('update-loading') ? req.headers.get('update-loading') : 'true';
  shimmers.push(updateIsLoading);
  if (updateIsLoading === 'true') {
      makeCallService.setLoading(true);
  }

  if (tokenStorage.getCurrentLanguage() && !req.headers.has('lang')) {
      req = req.clone({ headers: req.headers.set('lang', tokenStorage.getCurrentLanguage()) });
  }
  if (window['prefix'] && (req.url.startsWith('api') || req.url.startsWith('/api'))) {
      req = req.clone({ headers: req.headers.set('prefix', window['prefix']) });
  }
  req = req.clone({ headers: req.headers.set('X-Requested-With', 'XMLHttpRequest') });

  return next(req).pipe(
      takeUntil(authenticateService.onCancelPendingRequests$.asObservable()),
      tap(
          (event: any) => {
              if (event instanceof HttpResponse) {
                  loaderService.visibility = false;
                  if (event.body.exception) {
                      if (event.body === 'Unauthorized' || event.body.exception === 'NotAuthorizedException' || event.body.exception === 'SessionExpiredException') {
                          if (!alert_count) {
                              alert_count = 1;
                              alert.emitAlert({ type: 'danger', text: translate.instant("general.sessionexpired") });
                          }
                          sessionExpiredRedirection();
                      } else if (event.body.exception === 'BlockedException') {
                          if (!alert_count) {
                              alert_count = 1;
                              alert.emitAlert({ type: 'danger', text: translate.instant("general.userIsBlocked") });
                          }
                          sessionExpiredRedirection();
                      }
                  }
              }
          }
      ),
      catchError((error: HttpErrorResponse) => {
          loaderService.visibility = false
          if (error.status === 401) {
              if (!alert_count) {
                  alert_count = 1;
                  alert.emitAlert({ type: 'danger', text: translate.instant("general.sessionexpired") });
              }
              sessionExpiredRedirection();
              return EMPTY;
          }
          return throwError(error);
      }),
      finalize(() => {
          const index = shimmers.indexOf(updateIsLoading);
          if (index > -1) { shimmers.splice(index, 1); }
          if (!shimmers.includes("true")) {
              makeCallService.setLoading(false);
          }
      })
  );
}
