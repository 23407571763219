import { Injectable, Injector } from '@angular/core';
import { Routes, Router } from '@angular/router';
import { LazyLoadedComponent } from 'foo-framework';
import {
  AuthGuard,
  LoginComponent,
  PageNotFoundComponent,
  RouteService,
  OtpComponent
} from 'foo-framework';
import { RoutesMapper as FrameworkRoutesMapper } from 'foo-framework/routes-mapper';
// import { RoutesMapper as WalletRoutesMapper } from 'wallet-framework/routes-mapper';
// import {RoutesMapper as CorporateRoutesMapper} from 'corporate-framework/routes-mapper';
// import { RoutesMapper as DmpRoutesMapper} from 'dmp-framework/routes-mapper';
import { PagesComponent } from '../pages/pages.component';
import { IdvUsersDetailsComponent } from '../modules/idv-users/idv-users-details/idv-users-details.component';

export let routes: Routes = [
  {
    path: '',
    component: PagesComponent,
    canActivate: [AuthGuard],
    children: [],
  },
  {
    path: 'login',
    component: LoginComponent,
    data: {
      title: 'Login',
    },
  },
  {
    path: 'login-otp',
    component: OtpComponent,
    data: {
      title: 'Login',
    },
  },
];

export const modules = {
  BranchesModule: {
    load: () => import('../modules/branches').then(m => ({
      module: m.BranchesModule,
      components: m
    })),
    name: 'BranchesModule'
  },
  MyTerminalsModule: {
    load: () => import('../modules/my-terminals').then(m => ({
      module: m.MyTerminalsModule,
      components: m
    })),
    name: 'MyTerminalsModule'
  },
  BranchTransactionsModule: {
    load: () => import('../modules/branch-transactions').then(m => ({
      module: m.BranchTransactionsModule,
      components: m
    })),
    name: 'BranchTransactionsModule'
  },
  DownloadSdkModule: {
    load: () => import('../modules/download-sdk').then(m => ({
      module: m.DownloadSdkModule,
      components: m
    })),
    name: 'DownloadSdkModule'
  },
  MerchantConfigurationModule: {
    load: () => import('../modules/merchant-configuration').then(m => ({
      module: m.MerchantConfigurationModule,
      components: m
    })),
    name: 'MerchantConfigurationModule'
  },
  MerchantsModule: {
    load: () => import('../modules/merchants').then(m => ({
      module: m.MerchantsModule,
      components: m
    })),
    name: 'MerchantsModule'
  },
  SecurityModule: {
    load: () => import('../modules/security').then(m => ({
      module: m.SecurityModule,
      components: m
    })),
    name: 'SecurityModule'
  },
  IdvUsersModule: {
    load: () => import('../modules/idv-users').then(m => ({
      module: m.IdvUsersModule,
      components: m
    })),
    name: 'IdvUsersModule'
  },
  ClientsModule: {
    load: () => import('../modules/clients').then(m => ({
      module: m.ClientsModule,
      components: m
    })),
    name: 'ClientsModule'
  },
  CasIbanDirectoryModule: {
    load: () => import('../modules/cas-iban-directory').then(m => ({
      module: m.CasIbanDirectoryModule,
      components: m
    })),
    name: 'CasIbanDirectoryModule'
  },
  UsersModule: {
    load: () => import('../modules/users').then(m => ({
      module: m.UsersModule,
      components: m
    })),
    name: 'UsersModule'
  },
  UserAnalyticsModule: {
    load: () => import('../modules/user-analytics').then(m => ({
      module: m.UserAnalyticsModule,
      components: m
    })),
    name: 'UserAnalyticsModule'
  },
};

@Injectable({
  providedIn: 'root',
})
export class RouterService {
  initiatedRoutes = false;

  finalRoutes = [];
  routerRoutes: Routes = this.router['config'];

  /* exmaple
    ExmapleModule: {
      load: () => import('module path').then(m => ({
        module: m.ExmapleModule,
        components: m
      })),
      name: 'ExmapleModule'
    }
   */
  appAvailableComponents = [
    {
      name: 'MerchantConfigurationComponent',
      module: modules.MerchantConfigurationModule,
      componentName: 'MerchantConfigurationComponent'
    },
    {
      name: 'BranchesComponent',
      module: modules.BranchesModule,
      componentName: 'BranchesComponent',
    },
    {
      name: 'BranchDetailsComponent',
      module: modules.BranchesModule,
      componentName: 'BranchDetailsComponent'
    },
    {
      name: 'TerminalTransactionsComponent',
      module: modules.BranchTransactionsModule,
      componentName: 'TerminalTransactionsComponent'
    },
    {
      name: 'BranchTerminalsComponent',
      module: modules.BranchesModule,
      componentName: 'BranchTerminalsComponent'
    },
    {
      name: 'DownloadSdkComponent',
      module: modules.DownloadSdkModule,
      componentName: 'DownloadSdkComponent'
    },
    {
      name: 'MyTerminalsComponent',
      module: modules.MyTerminalsModule,
      componentName: 'MyTerminalsComponent'
    },
    {
      name: "BranchTransactionsComponent",
      module: modules.BranchTransactionsModule,
      componentName: "BranchTransactionsComponent"
    },
    {
      name: "MerchantComponent",
      module: modules.MerchantsModule,
      componentName: "MerchantsComponent"
    },
    {
      name: "MerchantDetailsComponent",
      module: modules.MerchantsModule,
      componentName: "MerchantDetailsComponent"
    },
    {
      name: "MerchantTransactionsComponent",
      module: modules.MerchantsModule,
      componentName: "MerchantTransactionsComponent"
    },
    {
      name: "SecurityComponent",
      module: modules.SecurityModule,
      componentName: "SecurityComponent"
    },
    {
      name: "IdvUsersDetailsComponent",
      module: modules.IdvUsersModule,
      componentName: "IdvUsersDetailsComponent"
    },
    {
      name: "IdVerificationUsersComponent",
      module: modules.IdvUsersModule,
      componentName: "IdVerificationUsersComponent"
    },
    {
      name: "MerchantQrCodeComponent",
      module: modules.MerchantsModule,
      parentComponents: [{
        module: modules.MerchantsModule,
        componentName: 'MerchantDetailsComponent'
      }],
      componentName: 'MerchantQrCodeComponent'
    },
    {
      name: "InAppWebCheckoutComponent",
      module: modules.MerchantsModule,
      parentComponents: [{
        module: modules.MerchantsModule,
        componentName: 'MerchantDetailsComponent'
      }],
      componentName: 'InAppWebCheckoutComponent'
    },
    {
      name: "MerchantBranchesComponent",
      module: modules.MerchantsModule,
      parentComponents: [{
        module: modules.MerchantsModule,
        componentName: 'MerchantDetailsComponent'
      }],
      componentName: 'MerchantBranchesComponent'
    },
    {
      name: "ClientsComponent",
      module: modules.ClientsModule,
      componentName: "ClientsComponent"
    },
    {
      name: "CasIbanDirectoryComponent",
      module: modules.CasIbanDirectoryModule,
      componentName: "CasIbanDirectoryComponent"
    },
    {
      name: 'UserComponent',
      module: modules.UsersModule,
      componentName: 'UsersComponent'
    },
    {
      name: 'UserAnalyticsComponent',
      module: modules.UserAnalyticsModule,
      componentName: 'UserAnalyticsComponent'
    }
  ];
  appPredefinedRoutesConfig = [

  ];

  frameworkAvailableComponents = [];
  walletAvailableComponents = [];
  corporateAvailableComponents = [];
  dmpAvailableComponents = [];

  frameworkPredefinedRoutesConfig = [];
  walletPredefinedRoutesConfig = [];
  corporatePredefinedRoutesConfig = [];
  dmpPredefinedRoutesConfig = [];

  availableComponents = [];
  preDefinedRouteConfigs = [];
  unhandledRoutes = [
    'login-otp',
    'check-number',
    'check-success',
    'forgot-password',
    'self-onboarding-signup',
    'self-onboarding',
    'check-username',
    'check-email',
    'password/resetpasswordview',
    'password/setpasswordview',
    '404',
  ];


  constructor(private injector: Injector, private router: Router) {
    try {
      this.frameworkAvailableComponents = FrameworkRoutesMapper.availableComponents || [];
      this.frameworkPredefinedRoutesConfig = FrameworkRoutesMapper.preDefinedRouteConfigs || [];
    } catch (e) {
      console.log('foo-framework is not included');
      this.frameworkAvailableComponents = [];
      this.frameworkPredefinedRoutesConfig = [];
    }

    // try {
    //   this.walletAvailableComponents = WalletRoutesMapper.availableComponents || [];
    //   this.walletPredefinedRoutesConfig = WalletRoutesMapper.preDefinedRouteConfigs || [];
    // } catch (e) {
    //   console.log('wallet is not included');
    //   this.walletAvailableComponents = [];
    //   this.walletPredefinedRoutesConfig = [];
    // }

    // try {
    //   this.corporateAvailableComponents = CorporateRoutesMapper.availableComponents || [];
    //   this.corporatePredefinedRoutesConfig = CorporateRoutesMapper.preDefinedRouteConfigs || [];
    // } catch (e) {
    //   console.log('corporate is not included');
    //   this.corporateAvailableComponents = [];
    //   this.corporatePredefinedRoutesConfig = [];
    // }

    // try {
    //   this.dmpAvailableComponents = DmpRoutesMapper.availableComponents || [];
    //   this.dmpPredefinedRoutesConfig = DmpRoutesMapper.preDefinedRouteConfigs || [];
    // } catch (e) {
    //   console.log('foo-dmp is not included');
    //   this.dmpAvailableComponents = [];
    //   this.dmpPredefinedRoutesConfig = [];
    // }


    this.availableComponents = this.appAvailableComponents
      .concat(this.dmpAvailableComponents)
      .concat(this.corporateAvailableComponents)
      .concat(this.walletAvailableComponents)
      .concat(this.frameworkAvailableComponents);

    this.preDefinedRouteConfigs = this.appPredefinedRoutesConfig
      .concat(this.dmpPredefinedRoutesConfig)
      .concat(this.corporatePredefinedRoutesConfig)
      .concat(this.walletPredefinedRoutesConfig)
      .concat(this.frameworkPredefinedRoutesConfig);
  }

  init(): void {
    this.injector.get(RouteService).initialize(this.availableComponents, this.preDefinedRouteConfigs, this.unhandledRoutes);
    this.initiatedRoutes = true;
  }
}
