import {Injectable} from '@angular/core';
import {CountryISO} from 'ngx-intl-tel-input';

@Injectable({
  providedIn: 'root'
})
export class CountryService {

  constructor() { }

  getCurrentCountry() {
    const _country = 'BH';
    return Object.keys(CountryISO).find(c => CountryISO[c] === _country.toLowerCase());
  }
}
